.Project__Search{
    background-color: $white;
    padding: 15px;
    border-radius: $border-radius-small;
    form{
        button{
            &.btn-primary{
                background-color: $primary-dark;
                border-color: $primary-dark;
                border-radius: $border-radius;
                transition: all 0.3s;
                width: 100%;
                &:hover{
                    background-color: $secondary-color;
                    border-color: $secondary-color;
                }
            }
        }
    }
}
.Projects__List{
    &__Budget{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 10px;
        &__Title{
            color: $secondary-color;
            font-weight: 500;
            text-transform: capitalize;
            h2{
                font-size: 0.95rem;
                font-weight: 500;
                margin-bottom: 0;
                display: inline-block;
            }
            h3{
                font-size: 0.95rem;
                font-weight: 500;
                margin-bottom: 0;
                display: inline-block;
            }
            h4{
                font-size: 0.95rem;
                font-weight: 500;
                margin-bottom: 0;
                display: inline-block;
            }
        }
        &__Desc{
            font-size: 0.85rem;
            color: $p-color;
        }
    }
    &__LeftSide{
        border-right: 1px solid $section-color-darker;
        padding-right: 20px;
        margin-right: 20px;
        min-width: 125px;
        max-width: 125px;
    }
    &__Container{
        margin-bottom: 50px;
    }
    .Dashboard__Project{
        display: flex;
    }
    .SkillList{
        margin: 0 0 15px;
        li{
            display: inline-block;
            list-style-type: none;
            margin-right: 10px;
            background-color: $section-color;
            border-radius: $border-radius;
            padding: 0px 12px;
            transition: all 0.3s;
            color: $dark-gray;
            font-size: 0.85rem;
        }
    }
}
.Project__Tips{
    h3{
        font-weight: 500;
        font-size: 1.2rem;
        color: $primary-color;
    }
    p{
        color: $p-color;
    }
    ul{
        li{
            list-style-type: decimal;
            list-style-position: inside;
        }
    }
}
.Project__Adding{
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}
[dir='rtl'] .Projects__List{
    &__LeftSide{
        border-left: 1px solid $section-color-darker;
        border-right: 0 none;
        padding-left: 20px;
        padding-right: 0;
        margin-left: 20px;
        margin-right: 0;
    }
    .SkillList{
        li{
            margin-left: 10px;
            margin-right: 0;
        }
    }
}