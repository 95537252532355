.browseBySkill {
    background-color: $section-color;

    header {
        h5 {
            color: $primary-color;
        }

        h4 {
            color: $pink;
        }
    }


    nav {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }

    ul {
        color: $primary-color;
        
        li {
            min-width: 48px;
            min-height: 48px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-family: inherit !important;

            a {
                color: $primary-color;
            }

            &::before {
                font-family: 'icomoon';
                color: $pink;
                padding-right: 10px;
                font-weight: 400;
                content: $right-arrow;
            }
        }
    }
}
[dir='rtl'] .browseBySkill {
    ul {
        li {
            &::before {
                padding-left: 10px;
                padding-right: 0;
            }
        }
    }
}