// Styles Override Bootstrap Styles.

.dropdown-toggle::after {
    border: none;
}

.dropdown-toggle {
    border-color: $white;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: $white;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.btn-primary:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
 .btn-primary:not(:disabled):not(.disabled):active:focus,
 .show>.btn-primary.dropdown-toggle:focus {
     box-shadow: none;
     -webkit-box-shadow: none;
 }

 .dropdown-menu {
     &--center {
        left: auto;
        right: calc(50% + 45px) !important;
        text-align: center;
    }
 }

 .btn-primary:hover {
     background-color: $secondary-color;
     border-color: $secondary-color;
 }