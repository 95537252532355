.lookingFor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    text-align: center;
    height: 400px;
    width: 100%;
  }
  &__project {
    background-image: url("../../imgs/lookingforfreelancer.webp");
  }

  &__freelancer {
    background-image: url("../../imgs/lookingforproject.webp");
  }

  h4 {
    font-size: 2.5rem;
    line-height: 1.5;
    color: $white;
  }

  .browseBtn {
    @extend %Button;
    font-size: 1.5625rem;
    margin-top: 1.5rem;
    color: $white;
  }

  .bg {
    background-attachment: unset;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    section {
      padding: 30px;
    }
  }
}
