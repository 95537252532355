.upload{
    &_Wrapper{
        position: fixed;
        bottom: 0;
        right: 0;
        background-color: $white;
        width: 400px;
        overflow: scroll;
        max-height: 400px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        z-index: 9999;
        h4{
            background-color: $white;
            margin: 0;
            padding: 20px;
        }
    }
    &_wrapperItem{
        display: grid;
        grid-template-columns: calc(100% - 50px) 50px;
        padding: 16px;
        box-shadow: 0px 1px 2px $section-color;
        .upload_leftSide{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 8px;
            label{
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
            }
        }
        .upload_progressBar{
            width: 100%;
            height: 15%;
            background-color: $section-color;
            margin-bottom: 8px;
            border-radius: 20px;
            div{
                height: 15px;
                background-color: lightgreen;
                border-radius: 20px;
            }
        }
        .upload_Percentage{
            margin-left: 12px;
        }
    }
}
[dir='rtl'] .upload{
    &_Wrapper{
        left: 0;
        right: auto;
    }
    &_wrapperItem{
        .upload_leftSide{
            margin-left: 8px;
            margin-right: 0;
            label{
                text-align: right;
            }
        }
        .upload_Percentage{
            margin-right: 12px;
            margin-left: 0;
        }
    }
}
.closeButton{
    position: absolute;
    top: 18px;
    right: 12px;
    background: transparent;
    border: unset;
    font-size: 18px;
    cursor: pointer;
    &:hover{
        opacity: 0.5;
    }
}
[dir='rtl'] .closeButton{
    left: 12px;
    right: auto;
}