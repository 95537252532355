.toprankedfreelancers {
    padding: 6.25rem 0;
    //padding-bottom: 8.5rem;
    h4 {
        color: $primary-color;
    }
    h5 {
        color: $pink;
    }
    header {
        &::after {
            display: flex;
            content: '';
            border-bottom: 3px solid $primary-color;
            width: 6.25rem;
            height: .5rem;
        }
    }
    p {
        color: $p-color;
        font-size: 1.25rem;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    &__content {
        display: block;
        @media (min-width: 1024px) {
            display: flex;
        }
        &__info {
            flex: 1;
            padding-right: 15px;
        }
        &__catsSlider {
            flex: 2 1;
            min-width: 0;
            .swiper-button-next{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: $white;
                background: rgb(255,96,125);
                background: -moz-linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
                background: -webkit-linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
                background: linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff607d",endColorstr="#623b6a",GradientType=1);
                left: 0;
                right: auto;
                &::after{
                    font-size: 1rem;
                    transform: rotate(90deg);
                }
                &::before{
                    content: '';
                    width: 3px;
                    height: 120px;
                    position: absolute;
                    bottom: -120px;
                    left: 18px;
                    background-color: $pink;
                }
            }
            .swiper-button-prev{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: $white;
                background: rgb(255,96,125);
                background: -moz-linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
                background: -webkit-linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
                background: linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff607d",endColorstr="#623b6a",GradientType=1);
                left: 60px;
                right: auto;
                &::after{
                    font-size: 1rem;
                    transform: rotate(-270deg);
                }
                &::before{
                    content: '';
                    width: 3px;
                    height: 120px;
                    position: absolute;
                    top: -120px;
                    left: 18px;
                    background-color: $purple-dark;
                }
            }
            .swiper-button-disabled{
                background: rgba(255,96,125,0.35);
                opacity: 1;
                &::after{
                    opacity: 0.35;
                }
            }
            @media screen and (max-width:768px) {
                .swiper-button-next{
                    bottom: 5px;
                    top: auto;
                    left: 50%;
                    margin-left: -20px;
                    &::before{
                        display: none;
                    }
                }
                .swiper-button-prev{
                    top: 25px;
                    left: 50%;
                    margin-left: -20px;
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
    .topfreelancer{
        &__freelancer{
            display: flex;
            padding: 15px;
            height: 100%;
            align-items: center;
            &__Img{
                overflow: hidden;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                border: 3px solid $white;
                margin-right: 15px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                min-width: 100px;
                img{
                    min-width: 100px;
                    min-height: 100px;
                    object-fit: cover;
                }
                .lazyload-wrapper{
                    width: 100%;
                }
            }
            &__Content{
                &__Title{
                    color: $primary-color;
                    font-size: 1.2rem;
                    a{
                        color: $primary-color;
                    }
                }
                &__Rate{
                    display: flex;
                    align-items: center;
                    .userRating{
                        margin-bottom: 0px;
                    }
                    .freelancer__hour__rate{
                        font-size: 1.2rem;
                        .hour__rate{
                            color: $primary-color;
                            font-weight: 900;
                        }
                        .hour__txt{
                            color: $p-color;
                        }
                    }
                }
                &__About{
                    margin: 0;
                    font-size: 0.85rem;
                    max-height: 40px;
                    overflow: hidden;
                    color: $form-color;
                    p{
                        margin: 0;
                        font-size: 0.85rem;
                    }
                }
                .ProfileTitle {
                    color: $p-color;
                    font-size: 1.1rem;
                    font-weight: normal;
                    margin-bottom: 5px;
                }
                .ProfileName{
                    width: 100%;
                    justify-content: flex-start;
                    color: $primary-color;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-bottom: 5px;
                    display: inline-flex;
                    flex-wrap: wrap;
                    .Project__Status{
                        margin-left: 10px;
                        margin-bottom: 0;
                    }
                    span {
                        font-weight: normal;
                        font-size: 0.85rem;

                        &.HourRate {
                            position: relative;
                            padding-left: 12px;
                            margin: 0 5px;
                            font-weight: 500;
                            font-size: 1.1rem;

                            &::before {
                                content: '';
                                position: absolute;
                                width: 5px;
                                height: 2px;
                                background-color: $primary-color;
                                left: 0;
                                top: 50%;
                                margin-top: -1px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 500px) {
                flex-direction: column;
                justify-content: center;
                &__Img{
                    margin-right: 0px;
                }
                &__Content{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .ProfileName{
                        justify-content: center;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
[dir='rtl'] .toprankedfreelancers {
    &__content {
        &__catsSlider {
            .swiper-button-next{
                right: 0;
                left: auto;
                &::before{
                    right: 18px;
                    left: auto;
                }
            }
            .swiper-button-prev{
                right: 60px;
                left: auto;
                &::before{
                    right: 18px;
                    left: auto;
                }
            }
            @media screen and (max-width:768px) {
                .swiper-button-next{
                    margin-right: -20px;
                    margin-left: 0;
                    right: 50%;
                    left: auto;
                }
                .swiper-button-prev{
                    margin-right: -20px;
                    margin-left: 0;
                    right: 50%;
                    left: auto;
                }
            }
        }
    }
    .topfreelancer{
        &__freelancer{
            &__Img{
                margin-left: 15px;
                margin-right: 0;
            }
            &__Content{
                .ProfileName{
                    span {
                        &.HourRate {
                            padding-right: 12px;
                            padding-left: 0;
                            &::before {
                                right: 0;
                                left: auto;
                            }
                        }
                    }
                    .Project__Status{
                        margin-right: 10px;
                        margin-left: 0;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                &__Img{
                    margin-left: 0px;
                    margin-right: 0;
                }
            }
        }
    }
}