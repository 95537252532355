.pagination{
    justify-content: center;
    .page-item{
        margin-right: 5px;
        .page-link{
            background-color: $white;
            border-radius: 50%;
            line-height: 1;
            border: 0 none;
            color: $dark-gray;
            transition: all 0.3s;
            &:hover{
                background-color: $secondary-color;
                color: $white !important;
            }
            &:focus{
                box-shadow:0 0 0 0.2rem rgba(255,183,39,.25);
                -webkit-box-shadow:0 0 0 0.2rem rgba(255,183,39,.25);
            }
        }
        &.active{
            .page-link{
                background-color: $secondary-color;
                color: $white;
            }
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
[dir='rtl'] .pagination{
    .page-item{
        margin-left: 5px;
        margin-right: 0;
        &:last-child{
            margin-left: 0;
            margin-right: 0;
        }
    }
}