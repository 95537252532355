.listing{
    &__title{
        color: $primary-color;
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: 500;
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 0px;
        h3{
            font-size: 1rem;
            display: inline-block;
            margin-bottom: 0;
            font-weight: 500;
        }
        h4{
            font-size: 1rem;
            display: inline-block;
            margin-bottom: 0;
            font-weight: 500;
        }
    }
    &__description{
        color: $form-color;
    }
}
.Service{
    &__Carousel{
        .slick-next{
            right: 10px;
            left: auto;
            top: 50%;
            bottom: auto;
            margin-top: -1.5rem;
            z-index: 2;
            background-color: $purple;
            transform: translate(0px, 0px);
            &::before{
                color: $white;
            }
        }
        .slick-prev{
            right: auto;
            left: 10px;
            top: 50%;
            bottom: auto;
            margin-top: -1.5rem;
            z-index: 2;
            background-color: $purple;
            transform: translate(0px, 0px);
            &::before{
                color: $white;
            }
        }
        .slick-slide{
            padding: 0;
            & > div{
                padding: 0;
            }
        }
        .slick-prev.slick-disabled, .slick-next.slick-disabled{
            background: rgba(255, 255, 255, 0.4) !important;
            border: 0;
            display: none !important;
            &::before{
                color: $primary-color;
            }
            &:hover{
                border: 0;
            }
        }
    }
    &__Features{
        &__Title{
            padding: 10px 15px;
            background-color: $purple-dark;
            color: $white;
            border-radius: 10px 10px 0 0;
            .form-group{
                margin-bottom: 0;
            }
            .form-label{
                margin-bottom: 0;
            }
        }
        &__Content{
            padding: 10px 15px 15px;
        }
    }
    &__Price{
        color: $primary-color;
        font-size: 1.3rem;
        text-transform: capitalize;
        font-weight: 500;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0px;
    }
    &__Info{
        color: $p-color;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
        span{
            &.icon{
                color: $secondary-color;
                font-size: 1.2rem;;
                margin-right: 5px;
            }
            &.number{
                color: $primary-color;
                margin-right: 5px;
            }
            &.icon-in-progress{
                font-size: 1.9rem;;
            }
        }
    }
    &__Status{
        display: flex;
        .Project__Status{
            margin-bottom: 0;
        }
    }
    &__Feature{
        &__Form{
            background-color: $section-color;
            padding: 16px;
            margin-bottom: 1.5rem;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &__Title{
            &__Open{
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    &__Invoice{
        &__ServiceCard{
            display: flex;
            width: 100%;
            align-items: center;
            .ServiceImage{
                width: 110px;
                min-width: 110px;
                height: 70px;
                overflow: hidden;
                border-radius: 3px;
                margin-right: 16px;
                img{
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 70px;
                    object-fit: cover;
                }
            }
            .ServiceContent{
                a{
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; 
                    max-height: 46px; 
                    overflow: hidden;
                    margin-bottom: 8px;
                    line-height: 1.4;
                }
                .Project__Status{
                    display: inline-flex;
                    align-items: center;
                    font-size: 0.75rem;
                    padding-right: 10px;
                    padding-left: 20px;
                    margin-bottom: 0;
                }
            }
        }
        &__Details{
            &__row{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 8px;
                .label{
                    color: $p-color;
                    font-weight: 500;
                    h3{
                        font-size: 0.95rem;
                        font-weight: 500;
                        margin-bottom: 0;
                        display: inline-block;
                    }
                    h4{
                        font-size: 0.95rem;
                        font-weight: 500;
                        margin-bottom: 0;
                        display: inline-block;
                    }
                }
                .value{
                    color: $dark-gray;
                }
                a{
                    color: $purple;
                    transition: all 0.3s linear;
                    position: relative;
                    padding-bottom: 2px;
                    &::after{
                        content: '';
                        width: 0px;
                        height: 2px;
                        background-color: $secondary-color;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transition: width 0.3s ease-in-out;
                    }
                    &:hover{
                        color: $secondary-color;
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.serviceFeedback{
    &__List{
        li{
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $section-color-darker;
            .Comment{
                &__userInfo{
                    display: flex;
                    margin-bottom: 10px;
                    .UserPic{
                        width: 40px;
                        height: 40px;
                        border-radius: 40px;
                        overflow: hidden;
                        margin-right: 10px;
                        img{
                            width: 40px;
                            min-height: 40px;
                            object-fit: cover;
                        }
                    }
                    .CommentContent{
                        &__Details{
                            width: calc(100% - 50px);
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            h3{
                                font-size: 1rem;
                                font-weight: 900;
                                margin-bottom: 0;
                                span{
                                    &.userType{
                                        float: right;
                                        color: $primary-color;
                                        padding-left: 10px;
                                        margin-left: 10px;
                                        position: relative;
                                        &::before{
                                            content: '';
                                            width: 1px;
                                            height: 15px;
                                            background-color: $secondary-color;
                                            position: absolute;
                                            top: 2px;
                                            left: 0px;
                                        }
                                    }
                                }
                            }
                            &__PostTime{
                                color: $p-color;
                                font-size: 0.75rem;
                            }
                        }
                    }
                    @media screen and (max-width: 410px) {
                        padding-top: 30px;
                    }
                }
            }
            .CommentContent{
                display: flex;
                justify-content: center;
                height: 100%;
                flex-direction: column;
                form{
                    .btn-secondary{
                        margin-left: 10px;
                    }
                }
                .ProfileSkills{
                    li{
                        border-bottom: 0 none;
                        position: inherit;
                    }
                }
            }
            .feedback{
                &__Seller{
                    background-color: $section-color-darker;
                    padding: 15px;
                    margin-top: 15px;
                    border-radius: $border-radius-smaller;
                }
                &__Buyer{

                }
            }
        }
    }
}

.serviceDelivery{
    display: flex;
    align-items: flex-start;
    &__Img{
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 100px;
        margin-right: 16px;
        img{
            max-width: 100%;
            min-height: 50px;
            min-width: 100%;
            object-fit: cover;
        }
    }
    &__Content{
        &__Message{
            font-weight: 500;
        }
        &__Actions{
            margin-top: 16px;
            button{
                &:first-of-type{
                    margin-right: 8px;
                }
            }
        }
    }
}

[dir='rtl'] .Service{
    &__Carousel{
        .slick-next{
            left: 10px;
            right: auto;
        }
        .slick-prev{
            left: auto;
            right: 10px;
        }
    }
    &__Price{
        margin-left: 10px;
        margin-right: 0;
    }
    &__Info{
        span{
            &.icon{
                margin-left: 5px;
                margin-right: 0;
            }
            &.number{
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
    &__Invoice{
        &__ServiceCard{
            .ServiceImage{
                margin-left: 16px;
                margin-right: 0;
            }
            .ServiceContent{
                .Project__Status{
                    padding-left: 10px;
                    padding-right: 20px;
                }
            }
        }
        &__Details{
            &__row{
                a{
                    &::after{
                        right: 0;
                    }
                }
            }
        }
    }
}
[dir='rtl'] .listing{
    &__title{
        margin-left: 5px;
        margin-right: 0;
    }
}
[dir='rtl'] .serviceFeedback{
    &__List{
        li{
            .Comment{
                &__userInfo{
                    .UserPic{
                        margin-left: 10px;
                        margin-right: 0px;
                    }
                    .CommentContent{
                        &__Details{
                            h3{
                                span{
                                    float: left;
                                    &.userType{
                                        padding-right: 10px;
                                        margin-right: 10px;
                                        &::before{
                                            right: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .CommentContent{
                form{
                    .btn-secondary{
                        margin-right: 10px;
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}

[dir='rtl'] .serviceDelivery{
    &__Img{
        margin-left: 16px;
        margin-right: 0;
    }
    &__Content{
        &__Actions{
            button{
                &:first-of-type{
                    margin-left: 8px;
                    margin-right: 0;
                }
            }
        }
    }
}