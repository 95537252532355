.loginToAdd{
    .Lock__Card__FullPage{
        margin: 80px 0;
        img{
            display: none;
        }
        .card-body{
            background-color: transparent;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0%);
            border-radius: 0;
            padding: 1.25rem 0;
        }
        h4{
            font-size: 1.87500rem;
        }
        p{
            font-size: 1.2rem;
            margin: 15px 0 20px;
        }
    }
}
.siteDesc{
    .headers{
        padding-bottom: 2.5rem;
        h5{
            color: $primary-color;
        }
        h4{
            color: $secondary-color;
        }
    }
    p{
        font-size: 1.1rem;
    }
}