%Button {
    span {
        flex: 0.2;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 48px;
        min-width: 48px;
        font-size: 1.2rem;
    }

    a {
        min-height: 48px;
        min-width: 48px;
        border: 2px solid $white;
        border-radius: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;

        span {
            justify-content: flex-start;
            &::after {
                content: '';
                border-right: 2px dashed $white;
                height: 33px;
                padding-left: 15px;
            }
        }

        &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
        }
    }
}

[dir='rtl'] %Button {
    a {
        span::after {
            padding-right: 15px;
            padding-left: 0;
            border-left: 2px dashed $white;
            border-right: 0 none;
        }
    }
}