.articles {
  background-image: url("../../imgs/articlebg.webp");
  background-attachment: unset;

  .headers--underline::after {
    border-color: white;
  }

  header {
    h4 {
      color: $secondary-color;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding-right: 0.5%;
    padding-left: 0.5%;

    .sectionHeader {
      padding-bottom: 0;
    }

    article {
      position: relative;
      border-radius: 25px;
      background-color: $white;
      margin: 0;
      margin-bottom: 2.1875rem;

      @media (min-width: 768px) {
        width: 49%;
        &:nth-child(3) {
          margin-top: 2.1875rem;
        }
      }

      @media (min-width: 1024px) {
        width: 31%;
        margin-top: 0 !important;
        margin-bottom: 0;
      }

      .goToTarget {
        left: 50%;
        margin-left: -20px;
      }

      .headers--underline::after {
        border-color: $pink;
        width: 3.25rem;
        margin-bottom: 1.5rem;
      }

      header {
        h1 {
          color: $primary-color;
          font-size: 1.1rem;
          margin-top: 1.5rem;
          width: 100%;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.5;
          a {
            color: $primary-color;
            transition: all 0.3s;
            &:hover {
              color: $pink;
            }
          }
        }

        figure {
          width: 100%;
          img {
            object-position: top;
            width: 100%;
            height: 230px;
            object-fit: cover;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
          }
        }
      }

      p {
        color: $p-color;
        padding-right: 7px;
        padding-left: 7px;
        font-size: 1rem;
        margin-bottom: 0;
        max-height: 51px;
        overflow: hidden;
      }
    }
  }
  &__description{
    padding-bottom: 2.375rem;
  }

  footer {
    display: flex;
    justify-content: center;
    .browseBtn {
      @extend %Button;
      color: $white;

      & a:hover {
        background-color: $pink !important;
        border-color: $pink !important;
      }
    }
  }
}
.articles__list {
  margin-bottom: 50px;
  .articles__content {
    padding: 0;
    justify-content: flex-start;
    article {
      width: 100%;
      margin-bottom: 50px;
      height: calc(100% - 50px);
    }
  }
}
