.breadcrumb__container{
    background-color: $purple-dark;
    nav{
        ol{
            &.breadcrumb{
                background-color: transparent;
                padding: .75rem 0;
                margin-bottom: 25px;
            }
            li{
                &.breadcrumb-item{
                    padding-left: calc(.5rem + 5px);
                    padding-right: 0;
                    position: relative;
                    margin-left: 0.5rem;
                    &::before{
                        content: '\E903';
                        font-family: "icomoon";
                        color: $white;
                        font-size: 0.5rem;
                        font-weight: 500;
                        padding-right: 0;
                        padding-left: 0;
                        margin-top: -6px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                    }
                    &.active{
                        color: $secondary-color;
                    }
                    &:first-child{
                        padding-left: 0;
                        &::before{
                            display: none;
                        }
                    }
                }
                a{
                    color: $white;
                }
            }
        }
    }
}
[dir='rtl'] .breadcrumb__container{
    nav{
        ol{
            li{
                &.breadcrumb-item{
                    margin-right: 0.5rem;
                    margin-left: 0;
                    padding-right: calc(.5rem + 5px);
                    padding-left: 0;
                    &::before{
                        content: '\E902';
                        right: 0;
                        left: auto;
                        padding-right: 0;
                        padding-left: 0;
                    }
                    &:first-child{
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
.form-check{
    padding-left: 1.25rem;
    padding-right: 0;
    &-input{
        margin-left: -1.25rem;
        margin-right: 0;
    }
}
[dir='rtl'] .form-check{
    padding-right: 1.25rem;
    padding-left: 0;
    &-input{
        margin-right: -1.25rem;
        margin-left: 0;
    }
}
.required{
    color: $red;
    font-weight: 900;
    font-size: 1.2rem;
}
.btn-primary{
    background-color: $primary-dark;
    border-color: $primary-dark;
    border-radius: $border-radius;
    transition: all 0.3s;
    padding: .375rem 1.75rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $secondary-color;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    &:hover{
        //background-color: $secondary-color;
        border-color: $secondary-color;
        &:before {
            width: 100%;
        }
    }
    &:focus{
        background-color: $secondary-color;
        border-color: $secondary-color;
    }
    &:disabled{
        background-color: $primary-dark !important;
        border-color: $primary-dark !important;
        cursor: default !important;
        opacity: 0.5;
        &::before{
            display: none;
        }
    }
    span{
        &.icon{
            margin-right: 5px;
        }
        &.fa{
            margin-right: 5px;
        }
        &.far{
            margin-right: 5px;
        }
        &.fas{
            margin-right: 5px;
        }
        &.fab{
            margin-right: 5px;
        }
    }
}
.btn-success{
    border-radius: $border-radius;
    transition: all 0.3s;
    padding: .375rem 1.75rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #1e7e34;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    span{
        &.icon{
            margin-right: 5px;
        }
        &.fa{
            margin-right: 5px;
        }
        &.far{
            margin-right: 5px;
        }
        &.fas{
            margin-right: 5px;
        }
        &.fab{
            margin-right: 5px;
        }
    }
    &:hover{
        background-color: #28a745;
        &:before {
            width: 100%;
        }
    }
}
.btn-disabled{
    background-color: $status-gray-color;
    border-color: $status-gray-color;
    border-radius: $border-radius;
    transition: all 0.3s;
    padding: .375rem 1.75rem;
    color: $white;
    opacity: 1 !important;
    cursor: default;
    &:hover{
        background-color: $status-gray-color;
        border-color: $status-gray-color;
        color: $white;
    }
    &:focus{
        background-color: $status-gray-color;
        border-color: $status-gray-color;
        color: $white;
    }
    span{
        &.icon{
            margin-right: 5px;
        }
        &.fa{
            margin-right: 5px;
        }
        &.far{
            margin-right: 5px;
        }
        &.fas{
            margin-right: 5px;
        }
        &.fab{
            margin-right: 5px;
        }
    }
}
.btn-secondary{
    background-color: transparent;
    border-color: $primary-dark;
    border-radius: $border-radius;
    color: $primary-dark;
    transition: all 0.3s;
    padding: .375rem 1.75rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $primary-dark;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    &:hover{
        background-color: transparent;
        border-color: $primary-color;
        color: $white;
        &:before{
            width: 100%;
        }
    }
    &:focus{
        background-color: $primary-dark;
        border-color: $primary-color;
        color: $white;
    }
    span{
        &.icon{
            margin-right: 5px;
        }
        &.fa{
            margin-right: 5px;
        }
        &.far{
            margin-right: 5px;
        }
        &.fas{
            margin-right: 5px;
        }
        &.fab{
            margin-right: 5px;
        }
    }
}
.btn-danger{
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #c82333;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    &:hover{
        background-color: #dc3545;
        &:before{
            width: 100%;
        }
    }
    span{
        &.icon{
            margin-right: 5px;
        }
        &.fa{
            margin-right: 5px;
        }
        &.far{
            margin-right: 5px;
        }
        &.fas{
            margin-right: 5px;
        }
        &.fab{
            margin-right: 5px;
        }
    }
}
.btn{
    border-radius: 25px;
    padding: .375rem 1.75rem;
}
.btn-sm{
    padding: .2rem 1.2rem !important;
}
[dir='rtl'] .btn-primary{
    span{
        &.icon{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fa{
            margin-left: 5px;
            margin-right: 0;
        }
        &.far{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fas{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fab{
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .btn-disabled{
    span{
        &.icon{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fa{
            margin-left: 5px;
            margin-right: 0;
        }
        &.far{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fas{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fab{
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .btn-success{
    span{
        &.icon{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fa{
            margin-left: 5px;
            margin-right: 0;
        }
        &.far{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fas{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fab{
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .btn-secondary{
    span{
        &.icon{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fa{
            margin-left: 5px;
            margin-right: 0;
        }
        &.far{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fas{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fab{
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .btn-danger{
    span{
        &.icon{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fa{
            margin-left: 5px;
            margin-right: 0;
        }
        &.far{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fas{
            margin-left: 5px;
            margin-right: 0;
        }
        &.fab{
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
.input-group{
    flex-direction: row-reverse;
    .input-group-prepend{
        margin-left: -1px;
        margin-right: auto;
    }
}
[dir='rtl'] .input-group{
    .input-group-prepend{
        margin-right: -1px;
        margin-left: auto;
    }
}
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
[dir='rtl'] .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), [dir='rtl'] .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), [dir='rtl'] .input-group>.input-group-append:not(:last-child)>.btn, [dir='rtl'] .input-group>.input-group-append:not(:last-child)>.input-group-text, [dir='rtl'] .input-group>.input-group-prepend>.btn, [dir='rtl'] .input-group>.input-group-prepend>.input-group-text{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
[dir='rtl'] .input-group>.custom-select:not(:first-child), [dir='rtl'] .input-group>.form-control:not(:first-child){
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.form-control{
    color: $dark-gray;
    &.hasAppend{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important;
    }
}
.input-group>.input-group-append>.input-group-text{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
    border-right: 0px;
}
.editor-textarea{
    border: 1px solid $section-color;
    padding: 10px;
    border-bottom-left-radius: $border-radius-smaller;
    border-bottom-right-radius: $border-radius-smaller;
    .DraftEditor-editorContainer{
        min-height: 150px;
        z-index: 0;
    }
    .public-DraftStyleDefault-block{
        margin: 0;
    }
}
.editor-toolbar-class{
    border-top-left-radius: $border-radius-smaller;
    border-top-right-radius: $border-radius-smaller;
}
.react-datepicker__year-option:first-child{
    background-color: #ccc;
    .react-datepicker__navigation--years-upcoming{
        border-bottom-color: #b3b3b3 !important;
    }
}
.react-datepicker__year-option:last-child{
    background-color: #ccc;
    .react-datepicker__navigation--years-previous{
        border-top-color: #b3b3b3 !important;
    }
}
.btn-link{
    background-color: transparent;
    color: $primary-color;
    border: 0 none;
    transition: all 0.3s;
    padding: 0 0 5px;
    position: relative;
    text-decoration: none;
    margin-right: 15px;
    &::after{
        content: '';
        width: 0;
        height: 2px;
        background-color: $primary-color;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.3s;
    }
    &:hover{
        color: $primary-color;
        text-decoration: none;
        background-color: transparent;
        border: 0 none;
        &::after{
            width: 100%;
        }
    }
    &:focus{
        text-decoration: none;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    }
    span{
        &.icon{
            font-size: 0.65rem;
        }
    }
}
[dir='rtl'] .btn-link{
    margin-left: 15px;
    margin-right: 0;
    &::after{
        right: 0;
        left: auto;
    }
}
.btn-link-delete{
    background-color: transparent;
    color: $red;
    border: 0 none;
    transition: all 0.3s;
    padding: 0 0 5px;
    position: relative;
    text-decoration: none;
    margin-right: 15px;
    &::after{
        content: '';
        width: 0;
        height: 2px;
        background-color: $red;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.3s;
    }
    &:hover{
        color: $red;
        text-decoration: none;
        background-color: transparent;
        border: 0 none;
        &::after{
            width: 100%;
        }
    }
    &:focus{
        text-decoration: none;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
    }
    span{
        &.icon{
            font-size: 0.65rem;
        }
    }
}
[dir='rtl'] .btn-link-delete{
    margin-left: 15px;
    margin-right: 0;
    &::after{
        right: 0;
        left: auto;
    }
}
.Form{
    &__Actions{
        button{
            &:first-of-type{
                margin-right: 15px;
            }
        }
    }
}
[dir='rtl'] .Form{
    &__Actions{
        button{
            &:first-of-type{
                margin-left: 15px;
                margin-right: 0;
            }
        }
    }
}
.userRating{
    margin-bottom: 10px;
    i{
        color: $secondary-color;
    }
}
.alert{
    padding: .5rem 1rem;
}
.tooltip{
    text-transform: inherit;
}
.player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
}
.react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
}

.react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
    top: 0;
}
.Connection{
    &__Lost{
        display: flex;
        justify-content: center;
        width: 100%;
        &__Container{
            width: 100%;
            background-color: $white;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius-smaller;
            box-shadow: $box-shadow;
            img{
                max-width: 125px;
            }
            h1{
                font-weight: 500;
                font-size: 1.5rem;
                color: $secondary-color;
                margin-top: 2.5rem;
                margin-bottom: 0.25rem;
            }
            p{
                color: $primary-color;
                margin-bottom: 0;
            }
        }
        .ConnectionLostImg{
            display: block;
            width: 200px;
            height: 176px;
            background-image: url('../../imgs/connectionLost.webp');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .SomethingWrongImg{
            display: block;
            width: 200px;
            height: 160px;
            background-image: url('../../imgs/somethingWrong.webp');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
.No{
    &__Data{
        display: flex;
        justify-content: center;
        width: 100%;
        &__Container{
            width: 100%;
            background-color: $white;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius-smaller;
            box-shadow: $box-shadow;
            text-align: center;
            img{
                max-width: 125px;
            }
            .NoDataImg{
                display: block;
                width: 125px;
                height: 140px;
                background-image: url('../../imgs/NoItems.webp');
                background-repeat: no-repeat;
                background-size: contain;
            }
            h1{
                font-weight: 500;
                font-size: 1.5rem;
                color: $secondary-color;
                margin-top: 2.5rem;
                margin-bottom: 0.25rem;
            }
            p{
                color: $primary-color;
                margin-bottom: 0;
            }
            button{
                text-transform: uppercase;
                margin-top: 10px;
                i{
                    margin-right: 5px;
                    font-size: 0.75rem;
                }
            }
        }
        .proposalImg{
            display: block;
            width: 200px;
            height: 172px;
            background-image: url('../../imgs/ProposalSent.webp');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
[dir='rtl'] .No{
    &__Data{
        &__Container{
            button{
                i{
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
        }
    }
}
.Verify{
    &_Account{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            max-height: 120px;
            margin-bottom: 20px;
        }
        h1{
            color: $primary-color;
            font-size: 1.2rem;
            font-weight: 900;
            margin-bottom: 10px;
            text-align: center;
            margin-top: 30px;
        }
        &_Img{
            display: block;
            width: 140px;
            height: 120px;
            background-image: url('../../imgs/mailbox.webp');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
.Lock{
    &__Card{
        &__FullPage{
            background-color: transparent;
            border-radius: 0;
            align-items: center;
            margin: 30px 15px;
            .card-body{
                background-color: $white;
                max-width: 500px;
                border-radius: $border-radius-smaller;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                padding: 1.25rem 3.5rem;
                .SignTo{
                    img{
                        max-height: 150px;
                        margin-bottom: 20px;
                    }
                    .LockImg{
                        display: block;
                        width: 173px;
                        height: 150px;
                        background-image: url(../../imgs/lock.webp);
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-bottom: 20px;
                    }
                }
                .icon-authentication{
                    color: $secondary-color;
                    font-size: 5rem;
                    margin-bottom: 8px;
                }
                @media screen and (max-width: 450px) {
                    padding: 1.25rem;
                }
            }
        }
    }
}
html, 
body,
#wrapper,
#root{
    height:100%; 
    padding:0; 
    margin:0; 
    width:100%;
}
#wrapper{
    display:flex;
    flex-direction:column;
}
.main{
    &__wrapper{
        flex-grow:1;
        &__content{
            min-height: 400px;
        }
    }
}
.mobile{
    &__input{
        direction: ltr;
        input.form-control{
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
        .input-group-append{
            .input-group-text{
                border-top-right-radius: 0rem !important;
                border-bottom-right-radius: 0rem !important;
                border-top-left-radius: 0.25rem !important;
                border-bottom-left-radius: 0.25rem !important;
                border-left: 1px solid #ced4da;
                border-right: 0;
            }
        }
    }
}
input{
    font-size: 0.95rem !important;
}
.input-group-text{
    font-size: 0.95rem !important;
}
.react-datepicker__month-read-view--selected-month{
    font-size: 1.2rem;
    font-weight: 500;
}
.react-datepicker__year-read-view--selected-year{
    font-size: 1.2rem;
    font-weight: 500;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
    top: 10px;
    border-top-color: $primary-color;
    margin-left: 25px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: $secondary-color;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: $secondary-color;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
    background-color: $primary-color;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: $primary-color;
    color: $white;
}
.custom-date-input{
    background-color: #fff !important;
}
[dir='rtl'] .custom-date-input{
    direction: ltr;
    text-align: right;
}
@media screen and (max-width: 768px) {
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
        width: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.1rem;
    }
}
.react-datepicker__navigation--years{
    line-height: 1.7;
    height: 25px;
    overflow: visible;
    text-indent: 0;
    &::after{
        content: '';
        width: 12px;
        height: 12px;
        border: 0.45rem solid transparent;
        display: inline-block;
        position: absolute;
        z-index: 1;
        left: -6px;
    }
}   
.react-datepicker__year-option:first-child .react-datepicker__navigation--years-upcoming{
    border-bottom-color: transparent !important;
    &::after{
        border-bottom-color: #b3b3b3 !important;
    }
}
.react-datepicker__year-option:last-child .react-datepicker__navigation--years-previous{
    border-top-color: transparent !important;
    &::after{
        border-top-color: #b3b3b3 !important;
    }
}
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option{
    font-size: 1.1rem;
    line-height: 2.5;
}
.dropdownViewAll{
    padding-bottom: 45px !important;
    li{
        a{
            &.dropdown-item{
                position: relative;
                padding-right: calc(1rem + 20px) !important;
                span{
                    &.unread{
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: 50%;
                        margin-top: -5px;
                        right: 1rem;
                        border-radius: 20px;
                        background-color: $secondary-color;
                    }
                }
            }
        }
    }
}
.dropdownViewAllLink{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    color: $white;
    background-color: $primary-color;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding: 0.5rem 0;
    border-bottom-right-radius: $border-radius-smaller;
    border-bottom-left-radius: $border-radius-smaller;
    transition: all linear 0.3s;
    .icon{
        margin: 0 8px;
    }
    .fa{
        margin: 0 8px;
    }
    .fas{
        margin: 0 8px;
    }
    .far{
        margin: 0 8px;
    }
    &:hover{
        background-color: $primary-dark;
        color: $secondary-color;
    }
    &:focus{
        background-color: $primary-dark;
        color: $secondary-color;
    }
}
[dir="rtl"] .dropdownViewAll{
    li{
        a{
            &.dropdown-item{
                padding-left: calc(1rem + 20px) !important;
                padding-right: 1rem !important;
                span{
                    &.unread{
                        right: auto;
                        left: 1rem;
                    }
                }
            }
        }
    }
}

/* Customize the label (the container) */
.form-check {
    display: block;
    position: relative;
    padding-left: 0;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    label{
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -12px;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $section-color;
        }
        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 50%;
            margin-top: -9px;
            width: 8px;
            height: 15px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
/* On mouse-over, add a grey background color */
.form-check:hover input ~ label::before {
    background-color: $section-color-darker;
}

/* When the checkbox is checked, add a blue background */
.form-check input:checked ~ label::before {
    background-color: $secondary-color;
}
/* Show the checkmark when checked */
.form-check input:checked ~ label:after {
    display: block;
}

.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label{
    &::before{
        background-color: $p-color;
    }
}

[dir="rtl"] .form-check {
    padding-right: 0;
    padding-left: 0;
    label{
        padding-right: 35px;
        padding-left: 0;
        &::before {
            right: 0;
            left: auto;
        }
        &::after {
            content: "";
            right: 9px;
            left: auto;
        }
    }
}
.btn-disabled{
    cursor: default !important;
}
.pagination{
    flex-wrap: wrap;
    .page-item{
        margin-bottom: 5px;
    }
}
.custom-link{
    color: $secondary-color;
    text-decoration: underline;
    transition: all 0.3s;
    &:hover{
        color: $primary-color;
        text-decoration: none;
    }
}
.card-rounded{
    background-color: $white;
    border-radius: $border-radius-smaller;
}
.alert-dismissible .close {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 2;
    padding: 0 1.25rem;
    margin-top: -15px;
}
.alert{
    p{
        margin-bottom: 0;
    }
}
[dir='rtl'] .alert-dismissible .close{
    right: auto;
    left: 0;
}

small{
    &.required{
        display: inline;
        font-weight: normal;
        font-size: 0.95rem;
    }
    &.optional{
        display: inline;
        font-weight: normal;
        font-size: 0.95rem;
        color: $p-color;
    }
}