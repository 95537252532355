.total__earnings{
    display: flex;
    margin-bottom: 30px;
    &__total{
        background-color: $primary-dark;
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        width: 50%;
        flex-direction: column;
        .icon{
            color: $secondary-color;
            font-size: 2.5rem;
        }
        h2{
            font-size: 1.2rem;
            margin: 10px 0 5px 0;
        }
        .total{
            font-size: 1.4rem;
        }
    }
    &__desc{
        background-color: $primary-color;
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        width: 50%;
        flex-direction: column; 
        h2{
            font-size: 0.8rem;
            margin: 10px 0 5px 0;
            text-transform: uppercase;
        }
        .total{
            font-size: 1.2rem;
            color: $secondary-color;
        }
    }
    @media screen and (max-width: 400px) {
        flex-wrap: wrap;
        flex-direction: column;
        &__total{
            width: 100%;
            border-top-left-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
            border-bottom-left-radius: 0;
        }
        &__desc{
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: $border-radius-small;
            border-bottom-left-radius: $border-radius-small;
        }
    }
}
[dir='rtl'] .total__earnings{
    &__total{
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &__desc{
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.total__projects{
    display: flex;
    margin-bottom: 30px;
    &__total{
        background-color: $purple-dark;
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        width: 50%;
        flex-direction: column;
        .icon{
            color: $secondary-color;
            font-size: 2.5rem;
        }
        h2{
            font-size: 1.2rem;
            margin: 10px 0 5px 0;
        }
        .total{
            font-size: 1.4rem;
        }
    }
    &__desc{
        background-color: $purple;
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        width: 50%;
        flex-direction: column; 
        h2{
            font-size: 0.8rem;
            margin: 10px 0 5px 0;
            text-transform: uppercase;
        }
        .total{
            font-size: 1.2rem;
            color: $secondary-color;
        }
    }
    @media screen and (max-width: 400px) {
        flex-wrap: wrap;
        flex-direction: column;
        &__total{
            width: 100%;
            border-top-left-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
            border-bottom-left-radius: 0;
        }
        &__desc{
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: $border-radius-small;
            border-bottom-left-radius: $border-radius-small;
        }
    }
}
[dir='rtl'] .total__projects{
    &__total{
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &__desc{
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.proposals__stats{
    background-color: $white;
    border-radius: $border-radius-small;
    margin-bottom: 30px;
    &__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
        .icon{
            color: $pink;
            font-size: 3.5rem;
        }
        h2{
            color: $primary-color;
            font-size: 1rem;
            margin: 10px 0 5px 0;
        }
    }
    &__desc{
        margin-top: 10px;
        span{
            color: $secondary-color;
            margin-right: 3px;
            font-weight: 900;
            font-size: 1.2rem;
        }
        color: $p-color;
        font-size: 0.8rem;
    }
    .row{
        .col-md-4{
            border-left: 1px solid $section-color;
            &:last-child{
                border-left: 0 none;
            }
        }
    }
}
.running__tasks{
    margin-bottom: 30px;
    .nav-tabs{
        margin-bottom: 30px;
        .nav-item{
            padding-left: 2.9rem;
            font-size: 1.2rem;
            position: relative;
            &#JoinedStats-tab-RunningProjects{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e92f';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-RunningContests{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e92d';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-RunningServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e922';
                    position: absolute;
                    left: 1rem;
                }
            }

            &#JoinedStats-tab-MyServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e922';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-RunningServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e93e';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-ClosedServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e922';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-CancelledServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e91c';
                    position: absolute;
                    left: 1rem;
                }
            }
        }
    }
}
.Dashboard__Project{
    background-color: $white;
    border-radius: $border-radius-small;
    box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    border-left: 5px solid $secondary-color;
    padding: 15px;
    margin-bottom: 30px;
    h2{
        color: $primary-color;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        a{
            color: $primary-color;
            font-weight: 500;
            font-size: 1.2rem;
            text-decoration: none;
            display: inline-block;
            transition: all 0.3s;
            margin-right: 10px;
            margin-bottom: 10px;
            word-wrap: break-word;
            &:hover{
                color: $secondary-color;
            }
        }
        div{
            display: inline-flex;
            flex-wrap: wrap;
        }
    }
    p{
        color: $p-color;
        margin-bottom: 15px;
    }
    &__Owner{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .Owner__Name{
            color: $dark-gray;
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 10px;
            .icon{
                color: $pink;
                font-size: 1.2rem;
                display: inline-block;
                margin-right: 7px;
            }
            &:last-child{
                margin-right: 0;
            }
            &__Rate{
                display: flex;
                margin-right: 10px;
                margin-bottom: 10px;
                a{
                    display: flex;
                    align-items: center;
                }
                .userRating{
                    margin-bottom: 0;
                    font-size: 0.7rem;
                }
            }
        }
        .Owner__Image{
            margin-right: 5px;
            img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
        }
        .Country__Flag{
            display: inline-flex;
            align-items: center;
            img{
                max-height: 10px;
                margin-right: 5px;
            }
        }
    }
    .SkillList{
        margin: 5px 0 15px;
        li{
            display: inline-block;
            list-style-type: none;
            margin-right: 10px;
            margin-top: 10px;
            background-color: $section-color;
            border-radius: $border-radius;
            padding: 3px 12px;
            transition: all 0.3s;
            color: $dark-gray;
            font-size: 0.85rem;
        }
    }
    &__Description{
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        color: $p-color;
        max-height: 79px;
        p{
            margin-bottom: 0;
        }
    }
    &__Budget{
        font-size: 1.25rem;
       .Owner__Name{
           display: inline-flex;
           align-items: center;
           span{
               &.icon{
                   color: $secondary-color;
                   margin-right: 8px;
               }
           }
       }
    }
    &__Category{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .Owner__Name{
            display: inline-flex;
            align-items: center;
            margin-right: 8px;
            margin-bottom: 8px;
            font-size: 0.8rem;
            span{
                &.icon{
                    color: $secondary-color;
                    margin-right: 8px;
                    font-size: 1.2rem;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}
.Project{
    &__Status{
        font-size: 0.9rem;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 28px;
        padding-right: 15px;
        border-radius: 20px;
        position: relative;
        line-height: 1;
        margin-bottom: 10px;
        &::before{
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            display: inline-block;
            top: 50%;
            margin-top: -4px;
            left: 10px;
        }
        &__Receiving__Bids{
            background-color: $status-orange-bg;
            color: $status-orange-color;
            &::before{
                background-color: $status-orange-color;
            }
        }
        &__In__Progress{
            background-color: $status-blue-bg;
            color: $status-blue-color;
            &::before{
                background-color: $status-blue-color;
            }
        }
        &__Closed{
            background-color: $status-shiraz-bg;
            color: $status-shiraz-color;
            &::before{
                background-color: $status-shiraz-color;
            }
        }
        &__In__Active{
            background-color: $status-gray-bg;
            color: $status-gray-color;
            &::before{
                background-color: $status-gray-color;
            }
        }
        &__Active{
            background-color: $status-green-bg;
            color: $status-success;
            &::before{
                background-color: $status-success;
            }
        }
        &__Delivered{
            background-color: $status-pink-bg;
            color: $status-pink-color;
            &::before{
                background-color: $status-pink-color;
            }
        }
    }
}
.Dashboard__tasks__List{
    margin: 25px 0;
    .nav-tabs{
        margin-bottom: 30px;
        .nav-item{
            padding-left: 2.9rem;
            font-size: 1.2rem;
            position: relative;
            &#JoinedStats-tab-MyServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e922';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-RunningServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e93e';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-ClosedServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e93f';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-CancelledServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e91c';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-AwaitingServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e946';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-MyProjects{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e92f';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-RunningProjects{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e93e';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-ClosedProjects{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e93f';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-CancelledProjects{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e91c';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-MyContests{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e92d';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-RunningContests{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e93e';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-ClosedContests{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e922';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-favouriteProjects{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e92f';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-favouriteServices{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e922';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-favouriteContests{
                &::before{
                    font-family: 'icomoon' !important;
                    content: '\e92d';
                    position: absolute;
                    left: 1rem;
                }
            }
            &#JoinedStats-tab-followers{
                padding: 0.5rem 1rem;
            }
            &#JoinedStats-tab-followings{
                padding: 0.5rem 1rem;
            }
            &#JoinedStats-tab-paypal{
                padding: 0.5rem 1rem;
            }
            &#JoinedStats-tab-bankAccount{
                padding: 0.5rem 1rem;
            }
            &#JoinedStats-tab-withdrawalRequests{
                padding: 0.5rem 1rem;
            }
        }
    }
    .tab-pane{
        .section__title{
            margin-top: 0;
        }
    }
    .section__title{
        margin-top: 0;
    }
}
.accordion{
    overflow: hidden;
}

.notificationList{
    background-color: $white;
    padding: 15px 0 20px;
    border-radius: $border-radius-small;
    ul{
        &.list{
            margin-bottom: 20px;
            li{
                border-bottom: 1px solid $section-color-darker;
                padding: 0px;
                a{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 16px;
                    padding-right: 32px;
                    position: relative;
                    white-space: inherit;
                    span{
                        &.userImg{
                            width: 40px;
                            height: 40px;
                            border-radius: 50px;
                            overflow: hidden;
                            margin-right: 10px;
                            min-width: 40px;
                            img{
                                min-width: 100%;
                                min-height: 40px;
                                max-height: 40px;
                                object-fit: cover;
                            }
                        }
                        &.unread{
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            top: 50%;
                            margin-top: -5px;
                            right: 10px;
                            border-radius: 20px;
                            background-color: #ffb727;
                        }
                    }
                    .notification{
                        &__Content{
                            display: inline-flex;
                            flex-flow: column;
                        }
                        &__Date{
                            color: $p-color;
                            display: inline-flex;
                            align-items: center;
                            span{
                                &.icon{
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                    .messageContent{
                        p{
                            margin-bottom: 0;
                        }
                    }
                    &:hover{
                        background-color: $primary-color;
                        .notification{
                            &__Date{
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    .ProjectDetails__Proposal__Card{
        header{
            .ProfileContent{
                .ProfileContent__Details{
                    .ProfileName{
                        a{
                            display: inline-block;
                            padding: 0;
                            &:hover{
                                background-color: transparent;
                            }
                        }
                    }
                }
                time{
                    display: inline-flex;
                    align-items: center;
                    span{
                        &.icon{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
    &.Inner{
        padding: 5px 20px 20px;
        ul{
            &.list{
                li{
                    padding: 10px 0;
                    a{
                        padding: 0.25rem 30px;
                    }
                }
                .ProjectDetails__Proposal__Content__attachments{
                    ul{
                        li{
                            padding: 0;
                            border-bottom: 0;
                            a{
                                padding: 0;
                                display: inline-block;
                                width: auto;
                                &:hover{
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.message{
    &__Details{
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        .title{
            color: $primary-color;
            font-weight: 900;
        }
        .content{
            color: $p-color;
            margin-left: 8px;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

.Withdrawal{
    &__List{
        padding-bottom: 14px;
        .row{
            .col-md-6{
                margin-bottom: 8px;
            }
        }
        b{
            display: inline-block;
            position: relative;
            padding-right: 5px;
            margin-right: 5px;
            &::after{
                content: ":";
                font-weight: bold;
                position: absolute;
                right: 0;
            }
        }
    }
}

.BankAccount{
    &__Data{
        padding-bottom: 14px;
        .row{
            .col-md-6{
                margin-bottom: 8px;
            }
        }
        b{
            display: inline-block;
            position: relative;
            padding-right: 5px;
            margin-right: 5px;
            &::after{
                content: ":";
                font-weight: bold;
                position: absolute;
                right: 0;
            }
        }
    }
}

.Invite{
    &__User{
        .form-check{
            label{
                width: 100%;
            }
        }
    }
}

[dir='rtl'] .message{
    &__Details{
        .content{
            margin-right: 8px;
            margin-left: 0;
        }
    }
}

[dir='rtl'] .notificationList{
    ul{
        &.list{
            li{
                a{
                    padding-right: 16px;
                    padding-left: 32px;
                    span{
                        &.userImg{
                            margin-left: 10px;
                            margin-right: 0;
                        }
                        &.unread{
                            right: auto;
                            left: 10px;
                        }
                    }
                    .notification{
                        &__Date{
                            span{
                                &.icon{
                                    margin-left: 8px;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

[dir='rtl'] .proposals__stats{
    .row{
        .col-md-4{
            border-left: 1px solid $section-color;
            border-right: 0 none;
            &:last-child{
                border-left: 0 none;
            }
        }
    }
}
[dir='rtl'] .running__tasks{
    .nav-tabs{
        .nav-item{
            padding-right: 2.9rem;
            padding-left: 1rem;
            &#JoinedStats-tab-RunningProjects{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-RunningContests{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-RunningServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }

            &#JoinedStats-tab-MyServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-RunningServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-ClosedServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-CancelledServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
        }
    }
}
[dir='rtl'] .Dashboard__Project{
    border-right: 5px solid $secondary-color;
    border-left: 0 none;
    h2{
        a{
            margin-left: 10px;
            margin-right: 0;
        }
    }
    &__Owner{
        .Owner__Name{
            margin-left: 10px;
            margin-right: 0;
            .icon{
                margin-left: 7px;
                margin-right: 0;
            }
            &:last-child{
                margin-left: 0;
                margin-right: 0;
            }
            &__Rate{
                margin-left: 10px;
                margin-right: 0;
            }
        }
        .Owner__Image{
            margin-left: 5px;
            margin-right: 0;
        }
        .Country__Flag{
            img{
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
    .SkillList{
        li{
            margin-left: 10px;
            margin-right: 0;
        }
    }
    &__Budget{
       .Owner__Name{
           span{
               &.icon{
                   margin-left: 8px;
                   margin-right: 0;
               }
           }
       }
    }
    &__Category{
        .Owner__Name{
            margin-left: 8px;
            margin-right: 0;
            span{
                &.icon{
                    margin-left: 8px;
                    margin-right: 0;
                }
            }
            &:last-child{
                margin-left: 0px;
                margin-right: 0;
            }
        }
    }
}
[dir='rtl'] .Project{
    &__Status{
        padding-right: 28px;
        padding-left: 15px;
        &::before{
            right: 10px;
            left: auto;
        }
    }
}
[dir='rtl'] .Dashboard__tasks__List{
    .nav-tabs{
        .nav-item{
            padding-right: 2.9rem;
            padding-left: 1rem;
            &#JoinedStats-tab-MyServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-RunningServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-ClosedServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-CancelledServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-AwaitingServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-MyProjects{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-RunningProjects{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-ClosedProjects{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-CancelledProjects{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-MyContests{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-RunningContests{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-ClosedContests{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-favouriteProjects{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-favouriteServices{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
            &#JoinedStats-tab-favouriteContests{
                &::before{
                    right: 1rem;
                    left: auto;
                }
            }
        }
    }
}

[dir='rtl'] .Withdrawal{
    &__List{
        b{
            padding-right: 0px;
            padding-left: 5px;
            margin-right: 0px;
            margin-left: 5px;
            &::after{
                right: auto;
                left: 0;
            }
        }
    }
}

[dir='rtl'] .BankAccount{
    &__Data{
        b{
            padding-right: 0px;
            padding-left: 5px;
            margin-right: 0px;
            margin-left: 5px;
            &::after{
                right: auto;
                left: 0;
            }
        }
    }
}
.custom-btn{
    color: $white !important;
    border: 1px solid #28a745 !important;
    padding: .375rem 1.75rem !important;
    animation: signal 1s 50;
    &:hover{
        border-color: #1e7e34 !important;
    }
    &:focus{
        border-color: #1e7e34 !important;
    }
    &:active{
        background-color: #28a745 !important;
    }
}
@keyframes signal{
    0%{
        box-shadow: 0 0 0 0 #5ae607;
    }
    100%{
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}