.mainFormStyle {
    margin: auto;
    border-radius: .25rem;
    border: 10px solid rgba($white, 0.2);
    color: $white;
    @media (min-width: 768px) {
        max-width: 80%;
        border-radius: 50px;
    }
    //overflow: hidden;
    .mainForm {
        color: $form-color;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        .MainSearchSelect{
            flex: 1 1;
            height: 60px;
            div[class*="control"]{
                height: 100%;
                border-radius: 0;
                border: 0 none;
                box-shadow: 0 0 0 0 transparent;
                -webkit-box-shadow: 0 0 0 0 transparent;
                &::before{
                    content: "";
                    height: 36px;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    margin-top: -18px;
                    border-left: 1px solid rgb(217, 217, 217);
                }
            }
            span[class*="indicatorSeparator"]{
                display: none;
            }
            div[class*="indicatorContainer"]{
                color: $pink;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
        }
        &__keywords {
            height: 60px;
            border: none;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            @media (min-width: 768px) {
                flex: 1;
            }
        }
        &__searchBtn {
            position: relative;
            font-family: inherit !important;
            border: none;
            height: 60px;
            color: $white;
            background: rgb(255,96,125);
            background: -moz-linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
            background: linear-gradient(90deg, rgba(255,96,125,1) 0%, rgba(98,59,106,0.4990371148459384) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff607d",endColorstr="#623b6a",GradientType=1);
            width: 100%;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            &::before {
                font-family: 'icomoon';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -65px;
                margin-top: -0.7rem;
                font-size: 1.6rem;
            }
            @media (min-width: 768px) {
                flex: 1;
            }
        }
    }
    @media screen and (max-width: 768px) {
        border: 0 none;
        .mainForm{
            .MainSearchSelect{
                width: 100%;
                margin-bottom: 20px;
                div[class*="control"]{
                    border-radius: 50px;
                    &::before{
                        display: none;
                    }
                }
            }
            &__keywords{
                border-radius: 50px;
                margin-bottom: 20px;
            }
            &__searchBtn{
                border-radius: 50px;
            }
        }
    }
}

.dropDownArrow {
    position: relative;
    font-family: inherit !important;
    width: 100%;
    @media (min-width: 1024px) {
        flex: 1;
    }
    &::before {
        font-family: 'icomoon';
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -0.25rem;
        font-size: 0.5rem;
        font-weight: 500;
        color: $pink;
    }
    @media (min-width: 1024px) {
        &:after {
            content: '';
            border-left: 2px solid #d9d9d9;
            height: 36px;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -18px;
        }
    }
}

.dropDownSharedStyle {
    width: 100%;
    color: inherit;
    appearance: none;
    height: 60px;
    border: none;
    padding-left: 20px;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
}

[dir='rtl'] .mainFormStyle {
    .mainForm {
        .MainSearchSelect{
            div[class*="control"]{
                &::before{
                    right: 0px;
                    left: auto;
                }
            }
        }
        &__keywords {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        &__searchBtn {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}
