.slick-prev,
.slick-next {
    border: 2px solid $purple;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    &:hover {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#623b6a+0,ff607d+100 */
        background: #623b6a; /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYyM2I2YSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjYwN2QiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left, #623b6a 0%, #ff607d 100%); /* FF3.6-15 */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#623b6a), color-stop(100%,#ff607d)); /* Chrome4-9,Safari4-5 */
        background: -webkit-linear-gradient(left, #623b6a 0%,#ff607d 100%); /* Chrome10-25,Safari5.1-6 */
        background: -o-linear-gradient(left, #623b6a 0%,#ff607d 100%); /* Opera 11.10-11.50 */
        background: -ms-linear-gradient(left, #623b6a 0%,#ff607d 100%); /* IE10 preview */
        background: linear-gradient(to right, #623b6a 0%,#ff607d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#623b6a', endColorstr='#ff607d',GradientType=1 ); /* IE6-8 */
        border-width: 0;
    }

    &:hover::before {
        color: $white;
    }
}

.slick-prev.slick-disabled, .slick-next.slick-disabled{
    opacity: .25;
    &:hover{
        background: transparent;
        border: 2px solid #623b6a;
        &::before{
            color: #623b6a;
        }
    }
}

[dir='rtl'] .slick-prev.slick-disabled, [dir='rtl'] .slick-next.slick-disabled{
    opacity: .25;
    &:hover{
        background: transparent;
        border: 2px solid #623b6a;
        &::before{
            color: #623b6a;
        }
    }
}

[dir='rtl'] .slick-prev,
[dir='rtl'] .slick-next {
    border: 2px solid $purple;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    &:hover {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#623b6a+0,ff607d+100 */
        background: #623b6a; /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYyM2I2YSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjYwN2QiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left, #623b6a 0%, #ff607d 100%); /* FF3.6-15 */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#623b6a), color-stop(100%,#ff607d)); /* Chrome4-9,Safari4-5 */
        background: -webkit-linear-gradient(left, #623b6a 0%,#ff607d 100%); /* Chrome10-25,Safari5.1-6 */
        background: -o-linear-gradient(left, #623b6a 0%,#ff607d 100%); /* Opera 11.10-11.50 */
        background: -ms-linear-gradient(left, #623b6a 0%,#ff607d 100%); /* IE10 preview */
        background: linear-gradient(to right, #623b6a 0%,#ff607d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#623b6a', endColorstr='#ff607d',GradientType=1 ); /* IE6-8 */
        border-width: 0;
    }

    &:hover::before {
        color: $white;
    }
}

.slick-prev {
    right: auto;
    left: -103%;
    bottom: -23%;
    top: auto;
}

[dir='rtl'] .slick-prev {
    left: auto;
    right: -103%;
    bottom: -23%;
    top: auto;
}

.slick-next {
    right: auto;
    left: calc(-103% + 48px + 11px);
    bottom: -23%;
    top: auto;
}

[dir='rtl'] .slick-next {
    left: auto;
    right: calc(-103% + 48px + 11px);
    bottom: -23%;
    top: auto;
}

@media screen and (max-width: 1024px) {
    .slick-prev {
        right: auto;
        left: calc(50% - 58px);
        bottom: -23%;
        top: auto;
    }
    
    [dir='rtl'] .slick-prev {
        left: auto;
        right: calc(50% - 58px);
        bottom: -23%;
        top: auto;
    }
    
    .slick-next {
        right: calc(50% - 58px);
        left: auto;
        bottom: -23%;
        top: auto;
    }
    
    [dir='rtl'] .slick-next {
        left: calc(50% - 58px);
        right: auto;
        bottom: -23%;
        top: auto;
    }  
}

.slick-prev::before,
.slick-next::before {
    content: $left-arrow;
    font-family: "icomoon";
    color: $purple;
    opacity: unset;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
}

[dir='rtl'] .slick-prev::before,
[dir='rtl'] .slick-next::before {
    content: "\e902";
    font-family: "icomoon";
    color: $purple;
    opacity: unset;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
}

.slick-next::before {
    content: $right-arrow;

}

[dir='rtl'] .slick-prev::before{
    content: "\e903";
}

.slick-slide {
    padding-right: 0.5%;
    & > div {
        padding-left: 10px;
        padding-top: 10px;
    }
}

[dir='rtl'] .slick-slide {
    padding-left: 0.5%;
    padding-right: 0;
    & > div {
        padding-right: 10px;
        padding-left: 0;
    }
}

.slick-list{
    overflow: hidden;
    padding-bottom: 30px;
}

.sliderItem {
    padding-right: 5px;
    padding-left: 5px;
    background-color: $white;
    border-radius: 25px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 250px;
    min-width: 6.83%;
    padding-top: 30px;
    i:first-child {
        background-color: #f2f2f2;
        border: 2px dashed $purple;
        border-radius: 50%;
        font-size: 3rem;
        width: 6.25rem;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: purple;
    }
    
    p {
        text-align: center;
        color: $primary-color;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.1rem;
    }
    @media screen and (max-width: 1024px) {
        min-height: 185px;
        padding-top: 0px;
        justify-content: center;
    }
}

.clientSteps{
    .sliderItem{
        height: 100%;
        padding: 30px 24px;
        h2{
            color: $primary-color;
            font-size: 1.2rem;
            margin-top: 10px;
            font-weight: 500;
        }
        p{
            color: $p-color;
        }
    }
    @media screen and (max-width: 768px) {
        .col-md-4{
            margin-bottom: 30px;
        }
    }
}