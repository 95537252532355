@font-face {
    font-family: 'Neo Sans Arabic';
    src: url('./fonts/NeoSansArabic.eot');
    src: url('./fonts/NeoSansArabic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/NeoSansArabic.woff2') format('woff2'),
        url('./fonts/NeoSansArabic.woff') format('woff'),
        url('./fonts/NeoSansArabic.ttf') format('truetype'),
        url('./fonts/NeoSansArabic.svg#NeoSansArabic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neo Sans Arabic';
    src: url('./fonts/NeoSansArabic-Black.eot');
    src: url('./fonts/NeoSansArabic-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/NeoSansArabic-Black.woff2') format('woff2'),
        url('./fonts/NeoSansArabic-Black.woff') format('woff'),
        url('./fonts/NeoSansArabic-Black.ttf') format('truetype'),
        url('./fonts/NeoSansArabic-Black.svg#NeoSansArabic-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neo Sans Arabic';
    src: url('./fonts/NeoSansArabic-Medium.eot');
    src: url('./fonts/NeoSansArabic-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/NeoSansArabic-Medium.woff2') format('woff2'),
        url('./fonts/NeoSansArabic-Medium.woff') format('woff'),
        url('./fonts/NeoSansArabic-Medium.ttf') format('truetype'),
        url('./fonts/NeoSansArabic-Medium.svg#NeoSansArabic-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}