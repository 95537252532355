@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?976zab');
  src:  url('fonts/icomoon.eot?976zab#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?976zab') format('truetype'),
    url('fonts/icomoon.woff?976zab') format('woff'),
    url('fonts/icomoon.svg?976zab#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-protection:before {
  content: "\e949";
}
.icon-add-friend:before {
  content: "\e948";
}
.icon-verify:before {
  content: "\e947";
}
.icon-payment:before {
  content: "\e946";
}
.icon-design-media:before {
  content: "\e944";
}
.icon-engineering:before {
  content: "\e950";
}
.icon-translation:before {
  content: "\e960";
}
.icon-administration:before {
  content: "\e967";
}
.icon-business:before {
  content: "\e945";
}
.icon-education:before {
  content: "\e979";
}
.icon-legal:before {
  content: "\e966";
}
.icon-sales-marketing:before {
  content: "\e943";
}
.icon-development:before {
  content: "\e942";
}
.icon-authentication:before {
  content: "\e941";
}
.icon-download:before {
  content: "\e940";
}
.icon-completed:before {
  content: "\e93f";
}
.icon-in-progress:before {
  content: "\e93e";
}
.icon-report:before {
  content: "\e93d";
}
.icon-more-solid:before {
  content: "\e93c";
}
.icon-more:before {
  content: "\e93b";
}
.icon-comment:before {
  content: "\e939";
}
.icon-view:before {
  content: "\e93a";
}
.icon-logout:before {
  content: "\e937";
}
.icon-notification:before {
  content: "\e938";
}
.icon-minus-small:before {
  content: "\e936";
}
.icon-proposals-limit:before {
  content: "\e933";
}
.icon-proposals-renewal:before {
  content: "\e934";
}
.icon-proposals-sent:before {
  content: "\e935";
}
.icon-upgrade:before {
  content: "\e932";
}
.icon-membership:before {
  content: "\e931";
}
.icon-edit:before {
  content: "\e930";
}
.icon-articles:before {
  content: "\e92c";
}
.icon-contests:before {
  content: "\e92d";
}
.icon-favourite:before {
  content: "\e92e";
}
.icon-projects:before {
  content: "\e92f";
}
.icon-desktop-screen:before {
  content: "\e92b";
}
.icon-add-user:before {
  content: "\e900";
}
.icon-angle-down:before {
  content: "\e901";
}
.icon-angle-left:before {
  content: "\e902";
}
.icon-angle-right:before {
  content: "\e903";
}
.icon-angle-up:before {
  content: "\e904";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-arrow-up:before {
  content: "\e908";
}
.icon-budget:before {
  content: "\e909";
}
.icon-business-case:before {
  content: "\e90a";
}
.icon-calender-time:before {
  content: "\e90b";
}
.icon-dashboard:before {
  content: "\e90c";
}
.icon-deal:before {
  content: "\e90d";
}
.icon-delete:before {
  content: "\e90e";
}
.icon-design-multimedia:before {
  content: "\e90f";
}
.icon-education-training:before {
  content: "\e910";
}
.icon-envelope:before {
  content: "\e911";
}
.icon-facebook:before {
  content: "\e912";
}
.icon-feedback:before {
  content: "\e913";
}
.icon-globe:before {
  content: "\e914";
}
.icon-google-plus:before {
  content: "\e915";
}
.icon-heart:before {
  content: "\e916";
}
.icon-help-support:before {
  content: "\e917";
}
.icon-Instagram:before {
  content: "\e918";
}
.icon-like:before {
  content: "\e919";
}
.icon-linkedin:before {
  content: "\e91a";
}
.icon-location:before {
  content: "\e91b";
}
.icon-lock:before {
  content: "\e91c";
}
.icon-minus:before {
  content: "\e91d";
}
.icon-paper-plane:before {
  content: "\e91e";
}
.icon-plus:before {
  content: "\e91f";
}
.icon-price-label:before {
  content: "\e920";
}
.icon-search:before {
  content: "\e921";
}
.icon-services:before {
  content: "\e922";
}
.icon-share:before {
  content: "\e923";
}
.icon-skills:before {
  content: "\e924";
}
.icon-time:before {
  content: "\e925";
}
.icon-twitter:before {
  content: "\e926";
}
.icon-user-group:before {
  content: "\e927";
}
.icon-user:before {
  content: "\e928";
}
.icon-website-development:before {
  content: "\e929";
}
.icon-youtube:before {
  content: "\e92a";
}
