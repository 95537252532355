.PortfolioDetails{
    .ProfileData{
        justify-content: center;
        flex-direction: column;
        .ProfileImage{
            width: 100px;
            height: 100px;
            margin-right: 0;
            margin-bottom: 20px;
            figure{
                width: 100px;
                height: 100px;
                img{
                    min-width: 100px;
                    min-height: 100px;
                }
            }
        } 
        .ProfileContent{
            display: flex;
            flex-direction: column;
            align-items: center;
            .ProfileName{
                text-align: center;
            }
            .ProfileTitle{
                a{
                    color: $p-color;
                    transition: all 0.3s;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        } 
    } 
    &__itemMargin{
        margin-bottom: 15px;
    }
    &__ItemCard{
        width: 100%;
        background-color: $primary-dark;
        padding: 25px 15px;
        margin-top: 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            width: 100%;
            font-weight: 500;
            color: $white;
            font-size: 1.2rem;
            text-align: center;
        }
        p{
            width: 100%;
            color: $p-color;
        }
        &__ItemStats{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 15px;
            &__Item{
                color: $white;
                font-weight: 500;
                margin-right: 15px;
                span{
                    margin-right: 5px;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &__LikeButton{
            width: 70px;
            height: 70px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-weight: 500;
            font-size: 2.2rem;
            background-color: $secondary-color;
            transition: all 0.3s;
            border: 0 none;
            border-radius: 50px;
            margin-bottom: 25px;
            &:hover{
                background-color: $primary-color;
            }
            &__dimmed{
                width: 70px;
                height: 70px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: $white;
                font-weight: 500;
                font-size: 1.2rem;
                background-color: $primary-color;
                border: 0 none;
                border-radius: 50px;
                margin-bottom: 25px;
                cursor: default !important;
                &:hover{
                    color: $white;
                    background-color: $primary-color;
                } 
                &:focus{
                    color: $white;
                    background-color: $primary-color;
                }
            }
        }
    }
    .Profile-Card{
        .btn-third{
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .Follow{
        &__User{
            background-color: $primary-color;
            border: 2px solid $primary-color;
            color: $white;
            border-radius: 25px;
            padding: 3px 10px;
            transition: all 0.3s;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
            align-items: center;
            &:hover{
                background-color: $secondary-color;
                border-color: $secondary-color;
                color: $white;
            }
            i{
                margin-right: 5px;
                font-size: 15px;
            }
        }
    }
    .slick-slider{
        .slick-list{
            padding-bottom: 0;
        }
        .slick-dots{
            bottom: -10px;
        }
    }
}
[dir='rtl'] .PortfolioDetails{
    .ProfileData{
        .ProfileImage{
            margin-left: 0;
            margin-right: 0;
        }
    }
    &__ItemCard{
        &__ItemStats{
            &__Item{
                margin-left: 15px;
                margin-right: 0;
                &:last-child{
                    margin-left: 0;
                }
            }
        }
    }
    .Follow{
        &__User{
            i{
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
}