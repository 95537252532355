.freelancers__listItem {
    flex-direction: column;
    border-radius: $border-radius;
    background-color: $white;
    height: 350px;
    position: relative;
    border-bottom: 5px solid $secondary-color;
    figure:first-child {
        flex-direction: column;
        height: 200px;
        overflow: hidden;
        img {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            width: 100%;
            max-height: 300px;
        }
    }

    &__home{
        .img-container{
            img{
                min-height: 150px;
                min-width: 150px;
                object-fit: cover;
            }
        }
    }

    figure:last-of-type {
        height: 150px;
        p {
            font-size: 1.2rem;
            color: $p-color;
            font-family: inherit !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            direction: ltr;
            &::before {
                font-family: 'icomoon';
                color: $secondary-color;
                margin-right: 0.6rem;
            }
        }

        figcaption {
            height: 90%;
            display: flex;
            padding-left: .9rem;
            padding-right: .9rem;
            padding-bottom: .9rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
    
    .goToTarget {
        left: 50%;
        margin-left: -20px;
    }
}