.freelancers {
  &.visible{
    background-image: url("/imgs/freelancers-bg.webp");
  }
  h5 {
    color: $secondary-color;
  }
  .headers--underline::after {
    border-color: $white;
  }

  .slick-list{
    padding-bottom: 1.2rem;
  }

  footer {
    @extend %Button;
    color: $white;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .slick-prev, .slick-next{
    border: 2px solid $white;
    bottom: -74px;
    &::before{
      color: $white;
    }
    &:hover{
      border: 0;
      background: $secondary-color;
    }
  }
  .slick-prev.slick-disabled, .slick-next.slick-disabled{
    background: transparent;
  }
  .slick-prev.slick-disabled:hover, .slick-next.slick-disabled:hover{
    border: 2px solid $white;
    &::before{
      color: $white;
    }
  }
  .slick-next{
    right: calc(50% - 145px);
    left: auto;
  }
  .slick-prev{
    left: calc(50% - 145px);
    right: auto;
  }
  footer{
    display: flex;
    align-items: center;
    justify-content: center;
    .lang-btn{
        min-width: 48px;
        min-height: 36px;
        border: 2px solid #fff;
        border-radius: 50px;
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        text-transform: uppercase;
        font-size: 0.95rem;
        cursor: pointer;
        background-color: transparent;
        span{
            display: inline-flex;
            flex-wrap: nowrap;
            flex: 1 1;
            min-height: auto;
            font-size: 0.95rem;
            font-weight: 500;
        }
        i{
            padding-right: 10px;
            position: relative;
            margin-right: 10px;
            &::after{
                content: '';
                border-right: 2px dashed #fff;
                height: 23px;
                position: absolute;
                right: 0px;
                top: -4px;
            }
        }
        &::before{
          background-color: transparent;
        }
        &:hover{
          &::before{
            background-color: $secondary-color;
          }
        }
    }
  }
}

[dir='rtl'] .freelancers {
  .slick-next{
    left: calc(50% - 145px);
    right: auto;
  }
  .slick-prev{
    right: calc(50% - 145px);
    left: auto;
  }
  footer{
    .lang-btn{
        i{
            padding-left: 10px;
            margin-left: 10px;
            padding-right: 0px;
            margin-right: 0px;
            &::after{
                left: 0px;
                right: auto;
            }
        }
    }
  }
}
