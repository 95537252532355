.services{
    &__listitem{
        width: 100%;
        background-color: $white;
        height: 317px;
        border-radius: $border-radius;
        margin-bottom: 30px;
        figure{
            width: 100%;
            border-top-left-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
            overflow: hidden;
            position: relative;
            a{
                &.services__imgcontainer{
                    display: flex;
                    width: 100%;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    height: 230px;
                    overflow: hidden;
                    img{
                        min-width: 100%;
                        height: 230px;
                        object-fit: cover;
                    }
                    &::after{
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: $secondary-color;
                        opacity: 0;
                        position: absolute;
                        z-index: 2;
                        transition: all 0.3s;
                    }
                    &::before{
                        font-family: 'icomoon' !important;
                        content: "\e907";
                        width: 50px;
                        height: 50px;
                        background-color: $primary-color;
                        color: $white;
                        text-align: center;
                        line-height: 52px;
                        border-radius: 50%;
                        position: absolute;
                        z-index: 3;
                        opacity: 0;
                        transition: all 0.3s;
                        font-size: 12px;
                    }
                    &:hover{
                        &::after{
                            opacity: 0.8;
                        }
                        &::before{
                            opacity: 1;
                        }
                    }
                }
            }
            figcaption{
                h2{
                    font-size: 1rem;
                    text-align: center;
                    margin-bottom: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    direction: ltr;
                    a{
                        padding: 15px;
                        width: 100%;
                        display: block;
                        transition: all 0.3s;
                        color: $dark-gray;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        direction: ltr;
                        &:hover{
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
        &.containsRemove{
            height: 400px !important;
        }
    }
    &__hiddenitem{
        opacity: 0.5;
    }
    &__info{
        display: flex;
        border-top: 1px solid $section-color;
        direction: ltr;
        .bought{
            display: flex;
            align-items: center;
            justify-content: center;
            color: $form-color;
            padding: 7px 0;
            width: 50%;
            border-right: 1px solid $section-color;
            direction: ltr;
            span.icon{
                color: $secondary-color;
                margin-right: 8px;
                font-size: 20px;
            }
        }
        .price{
            display: flex;
            align-items: center;
            justify-content: center;
            color: $form-color;
            padding: 7px 0;
            width: 50%;
            border-right: 1px solid $section-color;
            direction: ltr;
            span.icon{
                color: $secondary-color;
                margin-right: 8px;
                font-size: 20px;
            }
        }
    }
}
[dir='rtl'] .services{
    &__listitem{
        figure{
            a{
                &.services__imgcontainer{
                    &::before{
                        content: "\e906";
                    }
                }
            }
            figcaption{
                h2{
                    direction: rtl;
                    a{
                        direction: rtl;
                    }
                }
            }
        }
    }
    &__info{
        direction: rtl;
        .bought{
            direction: rtl;
            border-left: 1px solid $section-color;
            border-right: 0 none;
        }
        .price{
            direction: rtl;
            border-left: 1px solid $section-color;
            border-right: 0 none;
        }
    }
}
.portfolio{
    &__info{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 7px;
        .likes{
            color: $form-color;
            span.icon{
                color: $secondary-color;
                margin-right: 8px;
                font-size: 20px;
            }
        }
    }
}
[dir='rtl'] .services{
    &__info{
        .bought{
            span.icon{
                margin-left: 8px;
                margin-right: 0;
            }
        }
        .price{
            span.icon{
                margin-left: 8px;
                margin-right: 0;
            }
        }
    }
}