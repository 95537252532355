.mobileApp {
  background-image: url("../../imgs/mobileversionbg.webp");
  background-attachment: unset;
  position: relative;

  h4 {
    color: $secondary-color;
  }

  p {
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 30px;
    color: $white;
  }

  .headers--underline::after {
    border-color: $white;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    figure:first-of-type {
      display: none;
      justify-content: center;
      position: absolute;
      top: -18%;
      right: 17%;
      z-index: 0;
      @media (min-width: 1024px) {
        display: flex;
      }
    }

    figure:last-of-type {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        width: 427px;
      }
      img {
        border-radius: 8px;
        &:last-of-type {
          margin-top: 20px;
          @media (min-width: 1024px) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
[dir='rtl'] .mobileApp {
  &__content {
    figure:first-of-type {
      left: 17%;
      right: auto;
    }
  }
}
