.Contest {
    &__Card {
        background-color: $white;
        border-radius: $border-radius;
        padding: 20px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }

    &__Data {
        h2 {
            color: $primary-color;
            font-size: 1.1rem;
            font-weight: 500;
            margin-bottom: 10px;
            display: inline-flex;
            flex-wrap: wrap;
            .Project__Status{
                margin-left: 10px;
                margin-bottom: 0;
            }
        }
    }

    &__Date {
        display: flex;
        align-items: center;
        color: $p-color;
        margin-bottom: 10px;

        span {
            &.icon {
                color: $secondary-color;
                font-size: 1.1rem;
                margin-right: 5px;
            }
        }
    }

    &__Info {
        display: flex;
        flex-wrap: wrap;

        &__Col {
            padding: 0 10px;
            border-right: 1px solid $section-color-darker;

            .head {
                color: $primary-color;
                font-weight: 900;
                font-size: 1.1rem;
                text-align: center;
            }

            .desc {
                color: $p-color;
                text-align: center;
            }

            &:last-of-type {
                border-right: 0 none;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &__Holder {
        &__Info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &__Img {
                width: 65px;
                height: 65px;
                overflow: hidden;
                border-radius: 100px;
                margin-right: 5px;

                img {
                    max-width: 100%;
                    min-width: 100%;
                    min-height: 100%;
                }
            }

            &__User {
                a {
                    color: $primary-color;
                    transition: all 0.3s;

                    &:hover {
                        color: $secondary-color;
                    }
                }

                h3 {
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-bottom: 0;
                }

                &__Rating {
                    .userRating{
                        margin-bottom: 0;
                        margin-top: 5px;
                    }
                }
            }
        }

        &__Country {
            display: flex;
            align-items: center;

            img {
                max-height: 12px;
                margin-right: 5px;
            }
        }

        &__Follow {
            display: flex;

            .Follow {
                &__User {
                    background-color: $primary-color;
                    border: 2px solid $primary-color;
                    color: $white;
                    border-radius: 25px;
                    padding: 0px 10px;
                    transition: all 0.3s;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    align-items: center;

                    &:hover {
                        background-color: transparent;
                        color: $primary-color;
                    }

                    i {
                        margin-right: 5px;
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &__Body {
        background-color: $white;
        border-radius: $border-radius-smaller;
        padding: 20px;
        margin-bottom: 30px;
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        // align-items: center;
        margin-top: 16px;

        h3 {
            color: $primary-color;
            font-size: 1.2rem;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        &__Description {
            margin-bottom: 20px;
        }

        .Look {
            &__Feel {
                &__Option {
                    display: flex;
                    align-items: center;
                    width: 40%;
                    margin-bottom: 10px;

                    input[type="range"]:disabled::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        background: $secondary-color;
                        cursor: pointer;
                    }

                    input[type="range"]:disabled::-moz-range-thumb {
                        background: $secondary-color;
                        cursor: pointer;
                    }

                    span {
                        &:first-child {
                            margin-right: 10px;
                        }

                        &:last-child {
                            margin-left: 10px;
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        &__Colors {
            margin-bottom: 20px;

            li {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
            }

            &__Color {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                margin-bottom: 8px;
            }

            &__Hex {
                background-color: $section-color;
                border-radius: 3px;
                padding: 0px 5px;
                color: $primary-color;
                font-size: 0.85rem;
                direction: ltr;
            }
        }

        &__Links {
            li {
                display: block;
                color: $primary-color;

                a {
                    color: $primary-color;
                    transition: all 0.3s;
                    text-decoration: none;

                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
        }

        &__Images {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            margin-top: 10px;

            li {
                margin-right: 16px;
                margin-bottom: 16px;
                border-radius: $border-radius-small;
                overflow: hidden;
                display: inline-flex;

                a {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    background-color: $white;
                    max-width: 150px;
                    padding-bottom: 15px;

                    &:hover {
                        .Contest__Body__Images__Image {
                            span {
                                width: 100%;
                                height: 100%;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &__Image {
                width: 100%;
                height: 150px;
                overflow: hidden;
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                img {
                    object-fit: cover;
                    min-height: 150px;
                    max-height: 100%;
                }

                span {
                    position: absolute;
                    width: 0;
                    height: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($color: $purple, $alpha: 0.8);
                    opacity: 0;
                    transition: all ease-in-out 0.3s;

                    i {
                        width: 50px;
                        height: 50px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50px;
                        color: $white;
                        background-color: $purple-dark;
                        font-size: 1.2rem;
                    }
                }
            }

            &__Title {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 10px;
                margin-top: 10px;
                color: $primary-color;
            }

            &__Size {
                width: 100%;
                padding: 0 10px;
                color: $p-color;
                font-size: 0.85rem;
            }
        }
    }

    &__Designers {
        &__Container {
            &__Designer {
                width: 100%;
                background-color: $white;
                border-radius: $border-radius-smaller;
                padding: 75px 10px 10px;
                margin: 60px 0 30px;
                position: relative;

                .Designer__Info {
                    width: 100%;
                    display: flex;
                    flex-flow: column;
                    position: absolute;
                    top: -25px;
                    align-items: center;

                    &__Img {
                        width: 50px;
                        height: 50px;
                        display: inline-block;
                        overflow: hidden;
                        border-radius: 50px;
                    }

                    &__Name {
                        color: $primary-color;
                        font-weight: 500;
                        transition: all 0.3s;

                        &:hover {
                            color: $secondary-color;
                        }
                    }

                    &__Country {
                        display: flex;
                        align-items: center;

                        img {
                            max-height: 12px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.Entries {
    &__Container {
        margin-bottom: 30px;

        .Entry {
            &__Container {
                background-color: $white;
                border-radius: $border-radius-smaller;
                padding: 10px;
                margin-top: 16px;
            }

            &__Image {
                position: relative;

                a {
                    display: flex;
                    width: 100%;
                    height: 250px;
                    border-radius: $border-radius-smaller;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        min-width: 100%;
                        min-height: 250px;
                        transition: all 0.5s ease-in-out;
                    }

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            &__Details {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 8px;
                align-items: center;
            }

            &__Owner {
                display: flex;
                justify-content: center;

                &__Name {
                    a {
                        color: $primary-color;
                        transition: all 0.3s;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }

                &__Image {
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                    overflow: hidden;
                    margin-right: 5px;

                    img {
                        width: 100%;
                        min-height: 30px;
                    }
                }
            }

            &__Rating {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;

                &__Stars {
                    color: $primary-color;
                    .btn-primary{
                        padding: 0.1rem 1rem;
                    }
                }

                &__Like {
                    display: flex;
                    align-items: center;

                    &__btn {
                        // display: flex;
                        // align-items: center;
                        background-color: transparent;
                        border: 0 none;
                        color: $primary-color;
                        transition: all 0.3s;
                        padding: 0;

                        span {
                            float: right;
                        }

                        &:hover {
                            color: $secondary-color;
                        }

                        &__Liked {
                            // display: flex;
                            // align-items: center;
                            background-color: transparent;
                            border: 0 none;
                            color: $primary-color;
                            transition: all 0.3s;
                            padding: 0;

                            span {
                                float: right;
                            }

                            &:hover {
                                cursor: default;
                                color: $primary-color;
                            }
                        }
                    }

                    &__Icon {
                        margin-right: 2px;
                        float: left !important;
                    }
                }
            }

            &__Comment {
                width: 100%;

                &__Form {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-top: 8px;
                    padding-top: 8px;
                    border-top: 1px solid $section-color-darker;

                    .form-group {
                        width: 100%;

                        textarea {
                            border: 0 none;
                            padding: 0;
                            font-size: 0.85rem;
                            text-transform: capitalize;

                            &:focus {
                                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
                            }
                        }
                    }

                    .btn-primary {
                        width: 100%;
                        font-size: 0.85rem;
                        padding: .2rem 1.75rem;
                    }
                }
            }

            &__Report {
                position: absolute;
                top: 8px;
                right: 8px;
                z-index: 2;
                background-color: $primary-color;
                padding: 0px;
                border: 1px solid $secondary-color;
                border-radius: 50px;
                color: $white;
                font-size: 0.85rem;
                width: 30px;
                height: 30px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s;

                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }
}

.Status {
    &__Open {
        border-radius: $border-radius;
        padding: 3px 10px;
        background-color: $green;
        color: $white;
        font-weight: 500;
        font-size: 0.95rem;
        display: inline-block;
        margin-left: 10px;
    }
}

.Designer {
    &__Entries {
        &__Entry {
            width: 100%;

            &__Img {
                margin-bottom: 5px;

                a {
                    display: block;
                    width: 100%;
                    min-height: 120px;
                    max-height: 120px;
                    border-radius: $border-radius-smaller;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        transition: all 0.5s ease-in-out;
                    }

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            &__Rating {
                .fa-star {
                    color: $secondary-color;
                }
            }
        }

        .slick-list {
            padding-bottom: 0;
        }

        .slick-prev,
        .slick-next {
            border: 0 none;
            border-radius: 0;
            width: 2rem;
            height: 100%;
            transform: translate(0, 0);

            &:hover {
                border-width: 0;
                background: transparent;
            }

            &:hover::before {
                color: $primary-color;
            }
        }

        .slick-prev {
            right: auto;
            left: -1rem;
            bottom: 0;
            top: auto;
        }

        .slick-next {
            right: -1rem;
            left: auto;
            bottom: 0;
            top: auto;
        }
    }
}

[dir='rtl'] .Contest {
    &__Info {
        &__Col {
            border-left: 1px solid $section-color-darker;
            border-right: 0 none;
            &:first-child {
                padding-right: 0;
                padding-left: 0;
            }
            &:last-of-type {
                border-left: 0 none;
            }
            &:last-child {
                padding-left: 0;
            }
        }
    }
    &__Data {
        h2 {
            .Project__Status{
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }

    &__Date {
        span {
            &.icon {
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }

    &__Holder {
        &__Info {
            &__Img {
                margin-left: 5px;
                margin-right: 0;
            }
        }
        &__Country {
            img {
                margin-left: 5px;
                margin-right: 0;
            }
        }
        &__Follow {
            .Follow {
                &__User {
                    i {
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &__Body {
        .Look {
            &__Feel {
                &__Option {
                    span {
                        &:first-child {
                            margin-left: 10px;
                            margin-right: 0;
                        }
                        &:last-child {
                            margin-right: 10px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        &__Colors {
            li {
                margin-left: 16px;
                margin-right: 0;
            }
        }
        &__Images {
            li {
                margin-left: 16px;
                margin-right: 0;
            }
        }
    }

    &__Designers {
        &__Container {
            &__Designer {
                .Designer__Info {
                    &__Country {
                        img {
                            margin-left: 5px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

[dir='rtl'] .Entries {
    &__Container {
        .Entry {
            &__Owner {
                &__Image {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
            &__Rating {
                &__Like {
                    &__Icon {
                        margin-left: 2px;
                        margin-right: 0;
                    }
                }
            }
            &__Report {
                left: 8px;
                right: auto;
            }
        }
    }
}

[dir='rtl'] .Status {
    &__Open {
        margin-right: 10px;
        margin-left: 0;
    }
}
[dir='rtl'] .Designer {
    &__Entries {
        .slick-prev {
            left: auto;
            right: -1rem;
        }
        .slick-next {
            left: -1rem;
            right: auto;
        }
    }
}