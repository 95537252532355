//Fonts
$font-family: 'Neo Sans Arabic', sans-serif;

//Colors
$primary-color: #1d224e;
$primary-dark: #13173b;
$secondary-color: #ffb727;
$form-color: #545454;
$p-color: #545454;
// $form-color: #8a8a8a;
// $p-color: #8a8a8a;
$pink: #ffb727;
//$pink: #ff607d;
$section-color: #f7f4f8;
$section-color-darker: #edeaee;
$white: #fff;
$purple: #623b6a;
$purple-dark: #502f57;
$box-shadow: 0px 0px 10px 0px rgba(29, 34, 78, 0.1);
$border-radius: 25px;
$border-radius-small: 15px;
$border-radius-smaller: 10px;
$header-bottom-space: 3.75rem;
$dark-gray: #181818;
$red: #C90000;
$green: #13b30b;
//Status Colors
$status-orange-bg: #FDF2E0;
$status-orange-color: #ED8A00; 
$status-blue-bg: #E3F2F8;
$status-blue-color: #0065A1;
$status-shiraz-bg: #FBE2E8;
$status-shiraz-color: #B50644;
$status-gray-bg: #EEEEEE;
$status-gray-color: #888888;
$status-pink-bg: #ffeef2;
$status-pink-color: #ff607d; 
$status-green-bg: #F8FCE5;
$status-success: #B9D000;
$status-warning: #ED8A00;
$status-error: #B50644;

$right: right;
$left: left;
$direction: ltr;
$direction-opp: rtl;
$right-arrow: "\e903";
$left-arrow: "\e902";
$right-long-arrow: "\e907";
$left-long-arrow: "\e906";
$flex-direction-row: row;
$small-arrow-right: '\E903';
$small-arrow-left: '\E902';