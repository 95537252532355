.Offers__List{
    position: relative;
    height: 100%;
    .services__listitem{
        margin-bottom: 30px;
        height: 357px;
        figcaption{
            .Service__Provider{
                //position: relative;
                display: flex;
                //height: 120px;
                //flex-direction: column;
                //z-index: 2;
                align-items: center;
                justify-content: center;
                padding: 8px 0;
                flex-wrap: wrap;
                .img-container{
                    //top: -75px;
                    //bottom: auto;
                    //margin-bottom: auto;
                    position: inherit;
                    width: 24px;
                    height: 24px;
                    margin: 0;
                    bottom: 0;
                    margin-right: 8px;
                    img{
                        min-width: 24px;
                        min-height: 24px;
                        object-fit: cover;
                        vertical-align: inherit;
                    }
                }
                p{
                    //margin-top: 85px;
                    margin-bottom: 0;
                    font-size: 1.2rem;
                    color: $p-color;
                    font-family: inherit !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &::before{
                        font-family: 'icomoon';
                        color: $secondary-color;
                        margin-right: 0.6rem;
                    }
                }
            }
            h2{
                a{
                    border-top: 1px solid $section-color;
                    padding: 10px 15px;
                }
            }
        }
    }
}
[dir='rtl'] .Offers__List{
    .services__listitem{
        figcaption{
            .Service__Provider{
                .img-container{
                    margin-left: 8px;
                    margin-right: 0;
                }
                p{
                    &::before{
                        margin-left: 0.6rem;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}