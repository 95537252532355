.popup__wrapper {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    z-index: 9999;

    &__container {
        margin: auto;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        overflow: hidden;
        max-width: 360px;
        .account__type{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            h3{
                font-size: 1rem;
                font-weight: 500;
                color: $primary-color;
                margin-right: 10px;
            }
            &__types{
                display: flex;
                flex-wrap: wrap;
                .form-group{
                    margin-right: 20px;
                    margin-bottom: 10px;
                    .form-check{
                        label{
                            font-weight: 500;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }

        header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 25px;
            width: 100%;
            background-color: $white;
            figure {
                margin-top: 1.7rem;
                margin-bottom: 1.7rem;
            }

            .header__logo {
                width: 200px;
            }

            h1 {
                font-size: 1.4rem;
                color: $primary-color;
                font-weight: 900;
                margin-bottom: 1.7rem;
            }
        }

        form {
            width: 100%;
            padding: 0 25px;
            background-color: $white;
            .input__container {
                background-color: $section-color;
                border-radius: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 15px;
                margin-bottom: 10px;
                input {
                    border: none;
                    background-color: inherit;
                    font-family: inherit;
                    font-weight: 500;
                    padding-left: 10px;
                    width: calc(100% - 46px);
                }

                .dividerBTW {
                    border-right: 1px dashed $primary-color;
                    height: 20px;
                    margin-right: 0;
                }

                .icon__container {
                    width: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        color: $primary-color;
                        font-weight: 500;
                    }
                }
            }

            button {
                background-color: $secondary-color;
                color: $white;
                width: 100%;
                border: none;
                border-radius: 25px;
                margin-bottom: 10px;
                padding: 5px 15px;
                transition: all 0.3s;
                position: relative;
                overflow: hidden;
                z-index: 1;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background-color: $purple;
                    transition: all .3s;
                    border-radius: 10rem;
                    z-index: -1;
                }
                &:hover{
                    background-color: $secondary-color;
                    &:before{
                        width: 100%;
                    }
                }
            }
        }
        aside{
            width: 100%;
            padding: 0 25px;
            background-color: $white;
            .signSepa{
                text-align: center;
                position: relative;
                margin: 10px 0;
                &::before{
                    content: '';
                    width: 43%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    background-color: $section-color-darker;
                    top: 10px;
                }
                &::after{
                    content: '';
                    width: 43%;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    background-color: $section-color-darker;
                    top: 10px;
                }
            }
            h1 {
                font-size: 1.4rem;
                color: $primary-color;
                font-weight: 500;
                margin-bottom: 0.7rem;
                text-align: center;
            }
            p{
                color: $p-color;
                text-align: center;
                font-size: 0.75rem;
                a{
                    color: $pink;
                    text-decoration: none;
                    transition: all 0.3s;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .SocialLogin{
                display: flex;
                justify-content: center;
                margin-bottom: 0.7rem;
                li{
                    margin-right: 10px;
                    a{
                        color: $white;
                        span{
                            display: none;
                        }
                        i{
                            &.icon-facebook{
                                width: 35px;
                                height: 35px;
                                background-color: #3a589b;
                                border: 2px solid #3a589b;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                            }
                            &.icon-google-plus{
                                width: 35px;
                                height: 35px;
                                background-color: #d6492f;
                                border: 2px solid #d6492f;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                            }
                        }
                        &:hover{
                            color: transparent !important;
                            i{
                                &.icon-facebook{
                                    background-color: transparent;
                                    color: #3a589b;
                                }
                                &.icon-google-plus{
                                    background-color: transparent;
                                    color: #d6492f;
                                }
                            }  
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        footer{
            width: 100%;
            background-color: $primary-color;
            color: $white;
            text-align: center;
            padding: 10px;
            p{
                margin-bottom: 0;
                font-size: 0.9rem;
                button{
                    color: $secondary-color;
                    text-decoration: none;
                    transition: all 0.3s;
                    margin-left: 3px;
                    background: transparent;
                    border: 0 none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__errorsHead{
        color: $red;
    }
    &__errors{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 10px;
        li{
            position: relative;
            color: $red;
            padding-left: 20px;
            &::before{
                content: '\e907';
                position: absolute;
                left: 0;
                font-family: 'icomoon' !important;
                font-size: 6px;
                top: 50%;
                margin-top: -3px;
                speak: none;
                font-style: normal;
                font-weight: normal;
                -webkit-font-feature-settings: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
    button{
        &.forgotPassword{
            color: $secondary-color;
            text-decoration: none;
            transition: all 0.3s;
            margin-left: 3px;
            background: transparent;
            border: 0 none;
            &:before{
                display: none;
            }
            &:hover{
                text-decoration: underline;
                background-color: transparent;
            }
        }
    }
}
.SignContainer{
    background-color: $white;
    padding: 50px 30px;
    border-radius: $border-radius-smaller;
    box-shadow: $box-shadow;
    margin: 24px 0;
    &__fixed{
        display: flex;
        justify-content: center;
        .SignContainer{
            max-width: 480px;
        }
    }
    header{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        h1{
            text-align: center;
            color: $primary-color;
            font-size: 1.2rem;
            font-weight: 900;
            margin-bottom: 24px !important;
        }
        a{
            display: inline-block;
        }
    }
    .form-check{
        label{
            width: 100%;
            border-radius: $border-radius-smaller;
            padding: 20px;
            padding-left: 55px;
            padding-right: 20px;
            border: 1px solid #ced4da;
            &::before{
                left: 20px;
                right: auto;
            }
            &::after{
                left: 29px;
                right: auto;
            }
        }
    }
    footer{
        width: 100%;
        background-color: transparent;
        color: $primary-color;
        text-align: left;
        padding: 10px;
        p{
            margin-bottom: 0;
            font-size: 0.9rem;
            button{
                color: $secondary-color;
                text-decoration: none;
                transition: all 0.3s;
                margin-left: 3px;
                background: transparent;
                border: 0 none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    button{
        &.forgotPassword{
            color: $secondary-color;
            text-decoration: none;
            transition: all 0.3s;
            background: transparent;
            border: 0 none;
            &:before{
                display: none;
            }
            &:hover{
                text-decoration: underline;
                background-color: transparent;
            }
        }
    }
}
.verifyPage{
    background-color: $white;
    padding: 50px 30px;
    border-radius: $border-radius-smaller;
    box-shadow: $box-shadow;
    margin: 24px 0;
    .btn-primary{
        margin-right: 8px;
    }
}
[dir='rtl'] .popup__wrapper {
    &__container {
        .account__type{
            h3{
                margin-left: 10px;
                margin-right: 0;
            }
            &__types{
                .form-group{
                    margin-left: 20px;
                    margin-right: 0;
                    &:last-child{
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }
        }
        form {
            .input__container {
                input {
                    padding-right: 10px;
                    padding-left: 0;
                }
                .dividerBTW {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
        aside{
            .signSepa{
                &::before{
                    right: 0;
                    left: auto;
                }
                &::after{
                    left: 0;
                    right: auto;
                }
            }
            .SocialLogin{
                li{
                    margin-right: 10px;
                    margin-left: 0;
                    &:last-child{
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }
        }
        footer{
            p{
                button{
                    margin-left: 3px;
                    margin-right: 0;
                }
            }
        }
    }
    &__errors{
        li{
            padding-right: 20px;
            padding-left: 0;
            &::before{
                right: 0;
                left: auto;
            }
        }
    }
    button{
        &.forgotPassword{
            margin-left: 3px;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .SignContainer{
    .form-check{
        label{
            padding-right: 55px;
            padding-left: 20px;
            &::before{
                left: auto;
                right: 20px;
            }
            &::after{
                left: auto;
                right: 29px;
            }
        }
    }
    footer{
        text-align: right;
        p{
            button{
                margin-left: 3px;
                margin-right: 0;
            }
        }
    }
}
[dir='rtl'] .verifyPage{
    .btn-primary{
        margin-left: 8px;
    }
}
.modal-backdrop.show{
    opacity: 0.8;
}