.proprogram {
  background-color: $section-color;
  min-height: 500px;
  position: relative;
  z-index: 10;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;

    &__info {
      header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          font-size: 1.2rem;
          margin-top: 2.75rem;
        }
      }

      .whoareyou {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        div {
          border-radius: 25px;
          position: relative;
          background-color: $white;
          width: 49%;
          .goToTarget {
            left: 50%;
            margin-left: -20px;
          }

          p {
            margin-bottom: 0;
          }

          p:first-of-type {
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
            color: $white;
            font-size: 1.25rem;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
          }

          p:last-of-type {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 2.375rem;
            padding-bottom: 3.375rem;
            font-size: 1.1rem;
          }
          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .jobowner {
          p:first-of-type {
            background-color: $purple;
          }
        }

        .freelancer {
          @media (max-width: 768px) {
            margin-top: 2.1875rem;
          }

          p:first-of-type {
            background-color: $pink;
          }
        }
      }
      z-index: 2;
      @media (min-width: 1024px) {
        max-width: 60%;
      }
    }
  }

  p {
    color: $p-color;
    z-index: 2;
  }
  @media (min-width: 1024px) {
    &::before{
      content: '';
      background-image: url("../../imgs/proprogram.webp");
      background-repeat: no-repeat;
      min-height: 500px;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
[dir='rtl'] .proprogram {
  @media (min-width: 1024px) {
    &::before{
      content: '';
      background-image: url("../../imgs/proprogram.webp");
      background-repeat: no-repeat;
      min-height: 500px;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      transform: scaleX(-1);
      filter: FlipH;
    }
  }
}
