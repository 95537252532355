.main {
    padding-top: 100px;
    &__choosePills {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        min-height: 260px;
        color: $white;
        @media (min-width: 1024px) {
            flex-direction: row;
        }
        
        &--alignCenterXY {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        h2 {
            margin-top: 1.5rem;
            font-size: 1.5rem;
            min-width: 48px;
            min-height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .pill {
            &::after {
                content: '';
                width: 7rem;
                border-bottom: 3px solid transparent;
            }
            &:hover::after {
                border-color: $secondary-color;
            }
        }
    }
    nav{
        &.nav-tabs{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            //min-height: 260px;
            color: $white;
            background-color: transparent;
            border: 0 none;
            margin-bottom: 50px;
            @media (max-width: 500px) {
                flex-direction: column;
                margin-bottom: 20px;
            }
            a{
                &.nav-link{
                    color: $white;
                    font-size: 1.5rem;
                    padding: 70px 0 20px;
                    position: relative;
                    display: block;
                    overflow: hidden;
                    z-index: 3;
                    &::after{
                        content: '';
                        width: 0;
                        height: 3px;
                        background-color: $secondary-color;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -50px;
                        transition: all 0.3s;
                    }
                    &:hover{
                        &::after{
                            width: 100px;
                        }
                    }
                    &:first-child{
                        &::before{
                            content: '\E90A';
                            font-family: 'icomoon' !important;
                            speak: none;
                            font-style: normal;
                            font-weight: normal;
                            -webkit-font-feature-settings: normal;
                            font-feature-settings: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 2.5rem;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            margin-left: -22px;
                        }
                    }
                    &:last-child{
                        &::before{
                            content: '\E927';
                            font-family: 'icomoon' !important;
                            speak: none;
                            font-style: normal;
                            font-weight: normal;
                            -webkit-font-feature-settings: normal;
                            font-feature-settings: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 2.5rem;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            margin-left: -22px;
                        }
                    }
                    &:focus{
                        outline-width: 0px;
                    }
                    @media screen and (max-width: 500px) {
                        margin-bottom: 15px !important;
                    }
                }
                &.active{
                    background-color: transparent !important;
                    &::after{
                        width: 100px;
                    }
                }
            }
        }
    }
    &__chooseBehavior {
        padding-top: 4.25rem;
        padding-bottom: 5.625rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        color: $white;
        @media (max-width: 500px) {
            flex-direction: column;
        }
 
        &__posting {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            a{
                color: $white;
            }
        }

        &__applying {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            a {
                color: $white;
                min-width: 250px;
            }
        }

        @extend %Button;

    }
    .MainSearchSelect{
        &__indicator-separator{
            display: none;
        }
        &__dropdown-indicator{
            color: $pink;
        }
    }
}
.MainSlider{
    padding: 5rem 0;
    background-color: $white;
    .slideItem{
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        img{
            max-width: 40%;
        }
        &__Content{
            max-width: 40%;
            h2{
                font-size: 1rem;
                font-weight: 500;
                color: $primary-color;
                margin-bottom: 1rem;
            }
            h1{
                font-size: 2.5rem;
                font-weight: 500;
                color: $primary-color;
                padding-bottom: 1.5rem;
                position: relative;
                margin-bottom: 0;
                &::after{
                    content: "";
                    width: 4rem;
                    height: 5px;
                    background-color: #ffb727;
                    border-radius: 5px;
                    bottom: 0;
                    left: 0;
                    display: inline-block;
                    position: absolute;
                }
            }
            p{
                font-size: 1.25rem;
                margin: 2rem 0;
                color: $p-color;
            }
            .btn-primary{
                display: inline-flex;
                align-items: center;
                i{
                    font-size: 0.85rem;
                    margin-right: 8px;
                }
            }
        }
    }
    .slick-dots{
        z-index: 1;
        display: flex !important;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        li{
            width: 20px;
            height: 5px;
            button{
                padding: 0;
                width: 20px;
                height: 5px;
                &::before{
                    content: '';
                    width: 20px;
                    height: 5px;
                    border-radius: 5px;
                    background-color: $section-color-darker;
                }
            }
            &.slick-active{
                width: 40px;
                button{ 
                    width: 40px;
                    padding: 0;
                    &::before{
                        width: 40px;
                        background-color: $secondary-color;
                    }
                }
            }
        }
    }
    .slick-prev, .slick-next{
        bottom: -70px;
        top: auto;
        z-index: 2;
        border-color: $secondary-color;
        transition: all 0.3s ease-in-out;
        &::before{
            color: $secondary-color;
            transition: all 0.3s ease-in-out;
        }
        &:hover{
            color: $white;
            background: $secondary-color;
            &::before{
                color: $white;
            }
        }
        @media screen and (max-width: 768px) {
            bottom: -65px;
        }
    }
    .slick-prev{
        right: auto;
        left: calc(50% - 122px);
    }
    .slick-next{
        left: auto;
        right: calc(50% - 122px);
    }
    @media screen and (max-width: 768px) {
        .slideItem{
            flex-direction: column-reverse !important;
            align-items: center;
            justify-content: center;
            img{
                max-width: 100%;
            }
            &__Content{
                max-width: 100%;
                margin-top: 1.5rem;
            }
        }
    }
}
[dir='rtl'] .main {
    nav{
        &.nav-tabs{
            a{
                &.nav-link{
                    &::after{
                        right: 50%;
                        left: auto;
                        margin-right: -50px;
                        margin-left: 0;
                    }
                    &:first-child{
                        &::before{
                            right: 50%;
                            left: auto;
                            margin-right: -22px;
                            margin-left: 0;
                        }
                    }
                    &:last-child{
                        &::before{
                            right: 50%;
                            left: auto;
                            margin-right: -22px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.clientSteps{
    h5 {
        color: $secondary-color;
      }
      .headers--underline::after {
        border-color: $white;
      }
}
.mainHeading{
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}
.HowWorks{
    padding: 5rem 0;
    header{
        margin-bottom: 2rem;
        h2{
            font-size: 2rem;
            font-weight: 500;
            color: $primary-color;
            padding-bottom: 1.5rem;
            position: relative;
            text-align: center;
            &::after{
                content: "";
                width: 4rem;
                height: 5px;
                background-color: $secondary-color;
                border-radius: 5px;
                bottom: 0;
                left: 50%;
                margin-left: -2rem;
                display: inline-block;
                position: absolute;
            }
        }
    }
    h3{
        font-size: 1.8rem;
        color: $primary-color;
        margin-bottom: 2rem;
    }
    .nav-tabs{
        background-color: transparent;
        display: flex;
        justify-content: center;
        border-bottom: 0 none;
        .nav-link{
            background-color: $white;
            border-radius: $border-radius-smaller;
            padding: 0.75rem 1rem;
            margin-right: 1rem;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .tab-content{
        padding-top: 2rem;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .action_btn{
        padding-left: 16px;
        margin-top: 2rem;
        .btn{
            i{
                font-size: 0.75rem;
                margin-right: 8px;
            }
        }
    }
    .accordion{
        .card{
            margin-bottom: 1rem;
            .card-header{
                padding: 1rem;
                cursor: pointer;
                padding-right: 2.8rem;
                position: relative;
                background-color: $white;
                color: $primary-color;
                &::after{
                    content: "\e91f";
                    position: absolute;
                    color: $primary-color;
                    right: 1rem;
                    top: 50%;
                    margin-top: -7px;
                    font-size: 14px;
                    font-family: "icomoon"!important;
                    speak: none;
                    font-style: normal;
                    font-weight: 400;
                    -webkit-font-feature-settings: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                }
            }
            &.active-panel{
                .card-header{
                    background-color: $primary-color;
                    color: $white;
                    &::after{
                        content: "\e936";
                        color: $white;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.WhyLancersin{
    padding: 5rem 0;
    background-color: $white;
    header{
        margin-bottom: 2rem;
        h2{
            font-size: 2rem;
            font-weight: 500;
            color: $primary-color;
            padding-bottom: 1.5rem;
            position: relative;
            text-align: center;
            &::after{
                content: "";
                width: 4rem;
                height: 5px;
                background-color: $secondary-color;
                border-radius: 5px;
                bottom: 0;
                left: 50%;
                margin-left: -2rem;
                display: inline-block;
                position: absolute;
            }
        }
    }
    h3{
        font-size: 1.8rem;
        color: $primary-color;
        margin-bottom: 2rem;
    }
    .action_btn{
        padding-left: 16px;
        margin-top: 2rem;
        .btn{
            i{
                font-size: 0.75rem;
                margin-right: 8px;
            }
        }
    }
}
.Partners{
    padding: 5rem 0 3.5rem;
    background-color: $white;
    header{
        margin-bottom: 2rem;
        h2{
            font-size: 2rem;
            font-weight: 500;
            color: $primary-color;
            padding-bottom: 1.5rem;
            position: relative;
            text-align: center;
            &::after{
                content: "";
                width: 4rem;
                height: 5px;
                background-color: $secondary-color;
                border-radius: 5px;
                bottom: 0;
                left: 50%;
                margin-left: -2rem;
                display: inline-block;
                position: absolute;
            }
        }
    }
    &__List{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        img{
            max-height: 45px;
            margin-bottom: 1.5rem;
        }
    }
}

.FeaturesList{
    li{
        list-style-type: none;
        margin-bottom: 1.5rem;
        h4{
            color: $primary-color;
            font-size: 1.2rem;
            padding-left: 16px;
            position: relative;
            margin-bottom: 1rem;
            &::before{
                content: '';
                width: 8px;
                height: 8px;
                background-color: $secondary-color;
                border-radius: $border-radius-small;
                position: absolute;
                top: 50%;
                margin-top: -4px;
                left: 0px;
            }
        }
        p{
            margin-bottom: 0;
            padding-left: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.flex-row-reverse-mobile{
    @media screen and (max-width: 768px) {
        flex-flow: column-reverse;   
    }
}

[dir='rtl'] .FeaturesList{
    li{
        h4{
            padding-right: 16px;
            padding-left: 0;
            &::before{
                right: 0px;
                left: auto;
            }
        }
        p{
            padding-right: 16px;
            padding-left: 0;
        }
    }
}

[dir='rtl'] .HowWorks{
    .nav-tabs{
        .nav-link{
            margin-left: 1rem;
            margin-right: 0;
            &:last-child{
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .action_btn{
        padding-right: 16px;
        padding-left: 0;
        .btn{
            i{
                margin-left: 8px;
                margin-right: 0;
            }
        }
    }
    .accordion{
        .card{
            margin-bottom: 1rem;
            .card-header{
                padding-left: 2.8rem;
                padding-right: 1rem;
                &::after{
                    left: 1rem;
                    right: auto;
                }
            }
        }
    }
}

[dir='rtl'] .MainSlider{
    .slideItem{
        flex-flow: row-reverse;
        img{
            transform: scaleX(-1);
        }
        &__Content{
            h1{
                line-height: 1.5;
                &::after{
                    right: 0;
                    left: auto;
                }
            }
            .btn-primary{
                flex-flow: row-reverse;
                i{
                    margin-left: 8px;
                    margin-right: 0;
                }
            }
        }
    }
    .slick-dots{
        flex-direction: row-reverse;
    }
    .slick-prev, .slick-next{
        bottom: -70px;
        top: auto;
        z-index: 2;
        border-color: $secondary-color;
        transition: all 0.3s ease-in-out;
        &::before{
            color: $secondary-color;
            transition: all 0.3s ease-in-out;
        }
        &:hover{
            color: $white;
            background: $secondary-color;
            &::before{
                color: $white;
            }
        }
        @media screen and (max-width: 768px) {
            bottom: -65px;
        }
    }
    .slick-prev{
        left: auto;
        right: calc(50% - 122px);
    }
    .slick-next{
        right: auto;
        left: calc(50% - 122px);
    }
}

[dir='ltr'] .FlipImgEnglish{
    transform: scaleX(-1);
}
[dir='rtl'] .FlipImgArabic{
    transform: scaleX(-1);
}