.footer {
    background-color: $primary-color;
    padding-bottom: 0;
    padding-top: 2.5rem;

    nav {
        display: flex;
        flex-direction: column;
        @media (min-width: 1024px) {
            flex-direction: row;
        }
    }

    &__leftSide {
        display: flex;
        flex-direction: column;
        align-items: center;
        figure{
            a{
                span{
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.logoAr{
                        background-image: url('../../imgs/logo-ar.png');
                        width: 243px;
                        height: 60px;
                    }
                    &.logoEn{
                        background-image: url('../../imgs/logo.png');
                        width: 277px;
                        height: 60px;
                    }
                }
            }
            img{
                max-height: 70px;
            }
        }

        .chooseLang {
            position: relative;
            width: 100%;
            margin-top: 2.1875rem;
            
            
            button {
                &::before {
                    font-family: 'icomoon';
                    margin-right: 6px;
                    font-weight: 400;
                }
                font-family: inherit !important;
                width: 100%;
                display: flex;
                justify-content: center;
            }
            .show{
                .dropdown-menu{
                    left: 0 !important;
                    right: auto !important;
                    .dropdown-item{
                        &.active{
                            background-color: $primary-color;
                        }
                        &:active{
                            background-color: $primary-color;
                        }
                    }
                }
            }
        }

        .post-project{
            width: 100%;
            min-width: 48px;
            min-height: 36px;
            border: 2px solid #fff;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            text-transform: uppercase;
            margin-top: 1.5rem;
            font-size: 0.95rem;
            cursor: pointer;
            span{
                display: inline-flex;
                flex-wrap: nowrap;
                flex: 1 1;
                min-height: auto;
                font-size: 0.95rem;
                font-weight: 500;
            }
            i{
                padding-right: 10px;
                position: relative;
                margin-right: 10px;
                &::after{
                    content: '';
                    border-left: 2px dashed #fff;
                    height: 23px;
                    position: absolute;
                    right: 0px;
                    top: -4px;
                }
            }
        }

        .socialmedia {
            margin: 1.5rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            li {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid #fff;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                transition: all 0.3s;

                &:hover {
                    background-color: $secondary-color;
                    border-color: transparent;
                }

                a {                    
                    padding: 20px;
                    color: $white;
                    &:hover {
                        color: $white !important;
                    }
                    span{
                        display: none;
                    }
                }
            }
        }
        .btn-primary{
            background-color: transparent;
        }
        @media screen and (max-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
            .btn-primary{
                width: auto;
                margin-top: 0;
            }
            .socialmedia{
                width: auto;
                min-width: 200px;
                margin: 0;
            }
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            .btn-primary{
                width: auto;
                margin-top: 16px;
            }
            .socialmedia{
                width: auto;
                min-width: 200px;
                margin-top: 16px;
            }
        }
    }

    &__rightSide {
        flex: 3;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 2.1875rem;

        @media screen and (min-width: 1024px) {
            justify-content: space-around;
            margin-top: 0;
        }

        .list {
            margin-bottom: 2.1875rem;
            text-transform: capitalize;
            p {
                font-size: 1.2rem;
                color: $white;
                font-weight: 500;
                color: $secondary-color;
            }

            ul {

                li {
                    margin-bottom: .6rem;
                    a{
                        color: $white;
                    }
                }
            }
        }
        @media screen and (max-width: 400px) {
            .list{
                margin-right: 25px;
                &:last-of-type{
                    margin-right: 0;
                }
            }
        }
    }
    
    .copyright {
        height: 3.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        text-transform: uppercase;
        color: $white;
        font-size: 0.85rem;
        &__container{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            ul{
                li{
                    display: inline-block;
                    margin-right: 10px;
                    &.visa{
                        span{
                            height: 30px;
                            width: 43px;
                            display: inline-block;
                        }
                        &.visible{
                            span{
                                background-image: url('../../imgs/Visa.png');
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    &.master{
                        span{
                            height: 30px;
                            width: 51px;
                            display: inline-block;
                        }
                        &.visible{
                            span{
                                background-image: url('../../imgs/Mastercard.png');
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    &.paybal{
                        margin-right: 0;
                        span{
                            height: 30px;
                            width: 73px;
                            display: inline-block;
                        }
                        &.visible{
                            span{
                                background-image: url('../../imgs/Paypal.png');
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 529px) {
                justify-content: center;
                ul{
                    margin-bottom: 10px;
                }
            }
        }
        &__bg{
            background-color: $primary-dark;
        }
    }
}
[dir='rtl'] .footer {
    &__leftSide {
        .chooseLang {
            .show{
                .dropdown-menu{
                    right: 0 !important;
                    left: auto !important;
                }
            }
            button {
                &::before {
                    margin-left: 6px;
                    margin-right: 0;
                }
            }
        }
        .post-project{
            i{
                margin-left: 10px;
                margin-right: 0;
                padding-left: 10px;
                padding-right: 0;
                &::after{
                    left: 0px;
                }
            }
        }
    }

    &__rightSide {
        @media screen and (max-width: 400px) {
            .list{
                margin-left: 25px;
                margin-right: 0;
                &:last-of-type{
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .copyright {
        &__container{
            ul{
                li{
                    margin-left: 10px;
                    margin-right: 0;
                    &:last-child{
                        margin-left: 0;
                    }
                }
            }
        }
    }
}