.list_item {
    display: flex !important;
    justify-content: space-evenly;
    padding-bottom: 1rem;
    padding-top: 1rem;
    height: 400px;
    h3 {
        color: $primary-color;
        font-size: 1.2rem;
        text-align: center;
        padding-right: .1rem;
        padding-left: .1rem;
    }
    
    figure {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }
    
    .goToTarget {
        left: 50%;
        margin-left: -20px;
    }

    p {
        color: $p-color;
        font-size: 1rem;
        margin-bottom: 0;
    }

    .img-container {
        position: static;
        margin-left: 0;
        margin-bottom: 0;
    }

    .icon-budget {
        color: black;
        font-weight: 500;
    }

    .icon-budget,
    .icon-location,
    .icon-calender-time,
    .icon-user
    {
        font-family: inherit !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        &::before {
            font-family: 'icomoon';
            color: $secondary-color;
            padding-right: 10px;
            font-weight: 400;
            font-size: 1.2rem;
        }
    }

    .icon-location {
        font-weight: 500;
        &::before {
            color: $pink;
        }
    }
    
    .icon-location,
    .icon-calender-time {
        color: $p-color;
    }

    address {
        margin-bottom: 0;
    }

    &__infoUser {
        div {
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
        }
        .fixedPrice,
        .timeAndPlace,
        .userNameAndRate {
            padding-right: 1rem;
            padding-left: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .timeAndPlace {
            background-color: #f7f7f7;
        }
    }

    .tag {
        position: absolute;
        left: 0;
        top: 2.8125rem;
        background-color: $purple;
        width: 5.6rem;
        height: 1.6rem;
        margin-bottom: 0;
        color: $white;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}