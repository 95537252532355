.categories {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    h4 {
        color: $primary-color;
    }
    h5 {
        color: $pink;
    }
    header {
        &::after {
            display: flex;
            content: '';
            border-bottom: 3px solid $primary-color;
            width: 6.25rem;
            height: .5rem;
        }
    }
    p {
        color: $p-color;
        font-size: 1.25rem;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__content {
        display: block;
        @media (min-width: 1024px) {
            display: flex;
        }
        &__info {
            flex: 1;
            padding-right: 15px;
        }
        &__catsSlider {
            flex: 1;
            min-width: 0;
        }
    }
}