.Toast{
    &__Container{
        position: fixed;
        top: 39px;
        left: 39px;
        z-index: 100000;
        .toast{
            background-color: rgba(255,255,255,1);
            opacity: 1;
            position: fixed;
            left: -325px;
            max-width: 320px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            border: 0px;
            border-left: 4px solid $purple;
            &.Toast__Show{
                left: 39px;
            }
            &.Toast__Hide{
                left: -325px;
            }
        }
        .fade{
            transition: left 1.5s ease-in-out;
        }
        .Toast{
            &__Icon{
                i{
                    font-size: 30px;
                    margin-right: 0.5rem;
                    margin-left: 0;
                    color: $purple;
                }
            }
            &__Content{
                .toast-header{
                    justify-content: space-between;
                    padding-right: 1rem;
                    padding-left: 0px;
                    padding-top: 0.5rem;
                    padding-bottom: 0px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom: 0px;
                    strong{
                        color: $primary-color;
                    }
                    .close{
                        margin-left: 0.5rem !important;
                        margin-right: 0 !important;
                    }
                }
                .toast-body{
                    padding-right: 1rem;
                    padding-left: 0px;
                    padding-bottom: .75rem;
                    padding-top: 0px;
                    display: flex;
                    align-items: center;
                    min-width: 265px;
                }
            }
        }
        &__Success{
            .toast{
                border-left-color: $status-success;
            }
            .Toast__Icon{
                i{
                    color: $status-success;
                }
            }
        }
        &__Warning{
            .toast{
                border-left-color: $status-warning;
            }
            .Toast__Icon{
                i{
                    color: $status-warning;
                }
            }
        }
        &__Error{
            .toast{
                border-left-color: $status-error;
            }
            .Toast__Icon{
                i{
                    color: $status-error;
                }
            }
        }
    }
}
[dir='rtl'] .Toast{
    &__Container{
        right: 39px;
        left: auto;
        .toast{
            right: -325px;
            left: auto;
            padding-right: 0.5rem;
            padding-left: 0;
            border-right: 4px solid $purple;
            border-left: 0 none;
            &.Toast__Show{
                right: 39px;
                left: auto;
            }
            &.Toast__Hide{
                right: -325px;
                left: auto;
            }
        }
        .fade{
            transition: right 1.5s ease-in-out;
        }
        .Toast{
            &__Icon{
                i{
                    margin-left: 0.5rem;
                    margin-right: 0;
                }
            }
            &__Content{
                .toast-header{
                    padding-left: 1rem;
                    padding-right: 0px;
                    .close{
                        margin-right: 0.5rem !important;
                        margin-left: 0 !important;
                    }
                }
                .toast-body{
                    padding-left: 1rem;
                    padding-right: 0px;
                }
            }
        }
        &__Success{
            .toast{
                border-right-color: $status-success;
                border-left-color: transparent;
            }
        }
        &__Warning{
            .toast{
                border-right-color: $status-warning;
                border-left-color: transparent;
            }
        }
        &__Error{
            .toast{
                border-right-color: $status-error;
                border-left-color: transparent;
            }
        }
    }
}