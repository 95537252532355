.ProjectDetails{
    &__ProjectData{
        border-right: 0 none;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        .Projects__List__Budget{
            margin-right: 25px;
            margin-top: 5px;
            margin-bottom: 5px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .ProfileContent{
        width: 100%;
        .ProfileTitle{
            font-size: .85rem;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 0;
        }
        .Title__Status{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .ProfileName{
                margin-right: 15px;
            }
            .Status{
                background-color: $section-color;
                padding: 3px 15px;
                padding-left: 25px;
                border-radius: $border-radius-small;
                position: relative;
                display: inline-block;
                font-weight: 900;
                font-size: 0.85rem;
                margin-bottom: 10px;
                &::before{
                    content: '';
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    margin-top: -5px;
                    border-radius: $border-radius;
                }
                &.Open{
                    color: $green;
                    &::before{
                        background-color: $green;
                    }
                }
                &.Closed{
                    color: $form-color;
                    &::before{
                        background-color: $form-color;
                    }
                }
                &.Completed{
                    color: $purple;
                    &::before{
                        background-color: $purple;
                    }
                }
                &.InProgress{
                    color: $secondary-color;
                    &::before{
                        background-color: $secondary-color;
                    }
                }
            }
        }
        .Dashboard__Project__Owner{
            flex-wrap: wrap;
            align-items: center;
            .ProfileImage{
                width: 40px;
                height: 40px;
                margin-right: 5px;
                margin-bottom: 5px;
                figure{
                    width: 40px;
                    height: 40px;
                    img{
                        min-width: 40px;
                        min-height: 40px;
                    }
                }
            }
            .Projects__List__Budget{
                margin-right: 25px;
                margin-bottom: 0;
                &__Title{
                    color: $primary-color;
                }
            }
            .Owner__Name{
                margin-right: 15px;
                margin-bottom: 0px;
                .icon{
                    color: $secondary-color;
                }
                .userRating{
                    margin-bottom: 0;
                    margin-top: 3px;
                }
            }
            .LocationTime{
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
    }
    .HireMe{
        background-color:transparent !important;
        border: 0 none;
        color: transparent;
        border-radius: 0;
        padding: 0;
        transition: all 0.3s;
        width: 100%;
        display: block;
        margin-bottom: 0;
        position: inherit;
        a{
            background-color: $secondary-color;
            border: 2px solid $secondary-color;
            color: $white;
            border-radius: $border-radius;
            padding: 3px 10px;
            transition: all 0.3s;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            position: relative;
            &:hover{
                background-color: transparent !important;
                color: $secondary-color;
                border-color: $secondary-color !important;
            }
            &:active{
                background-color: transparent !important;
                border-color: $secondary-color !important;
                color: $secondary-color !important;
            }
            span{
                position: initial;
                right: 0px;
                padding-left: 0px;
                height: auto;
                display: flex;
                align-items: center;
                top: 0;
                i{
                    font-size: 17px;
                    margin-right: 5px;
                }
                &::before{
                    display: none;
                }
            }
        }
    }
    &__Proposal{
        &__Card{
            padding-bottom: 15px;
            border-bottom: 1px solid $section-color-darker;
            margin-bottom: 15px;
            header{
                display: flex;
                margin-bottom: 8px;
                align-items: center;
                position: relative;
                .dropdown{
                    position: absolute;
                    right: 0;
                    top: 0;
                    button{
                        border-radius: 50%;
                        background-color: $white;
                        transition: all 0.3s;
                        width: 40px;
                        height: 40px;
                        padding: 0;
                        &::after{
                            display: none;
                        }
                        &:hover{
                            background-color: $section-color;
                        }
                        &[aria-expanded="true"]{
                            background-color: $section-color;
                        }
                        &:focus{
                            box-shadow: 0 0 0 rgba(0,0,0,0);
                            -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
                        }
                    }
                    .dropdown-menu{
                        border: 0 none;
                        box-shadow: $box-shadow;
                        -webkit-box-shadow: $box-shadow;
                        width: auto;
                        a{
                            span{
                                margin-right: 5px;
                                width: 20px;
                                display: inline-block;
                                &.icon{
                                    font-size: 17px;
                                }
                            }
                        }
                    }
                }
                .ProfileData{
                    display: flex;
                    justify-content: center;
                    @media screen and (max-width: 500px) {
                        flex-direction: column;
                        width: 100%;
                    }
                }
                .ProfileImage{
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    figure{
                        position: static;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;
                        img{
                            display: block;
                            width: 50px;
                            min-height: 50px;
                            min-width: 50px;
                        }
                    }
                }
                .ProfileContent{
                    //width: 100%;
                    &__Details{
                        display: flex;
                        align-items: center;
                        address{
                            margin-bottom: 0;
                            margin-right: 10px;
                            .LocationFlag{
                                img{
                                    max-width: 20px;
                                }
                            }
                        }
                        .ProfileName{
                            margin-bottom: 0;
                            font-size: 1.1rem;
                            margin-right: 5px;
                            color: $primary-color;
                            font-weight: 500;
                            display: inline-flex;
                            flex-wrap: wrap;
                            a{
                                color: $primary-color;
                                font-weight: 500;
                                font-size: 1.1rem;
                                &:hover{
                                    color: $secondary-color;
                                }
                            }
                            .userRating{
                                margin-bottom: 0;
                                margin-left: 10px;
                                i{
                                    font-size: 0.85rem;
                                }
                            }
                        }
                        .ProfileUserName{
                            margin-bottom: 0;
                            color: $form-color;
                            font-weight: normal;
                            font-size: 0.95rem;
                        }
                    }
                    &__Cost{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        &__Price{
                            color: $secondary-color;
                            font-size: 1.1rem;
                            margin-right: 5px;
                            font-weight: 500;
                            span{
                                color: $primary-color;
                            }
                        }
                        &__Days{
                            color: $primary-color;
                            font-size: 0.85rem;
                        }
                        time{
                            display: flex;
                            align-items: center;
                            font-size: 0.85rem;
                            color: $form-color;
                            margin-left: 3px;
                            span{
                                &.icon{
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    &__Cats{
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            li{
                                margin-right: 10px;
                                font-size: 0.85rem;
                                margin-bottom: 5px;
                                color: $form-color;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
                .ProposalActions{
                    @media screen and (max-width: 530px) {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        button{
                            width: 100%;
                            margin-bottom: 15px !important;
                            &:nth-child(2){
                                margin-left: 0 !important;
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
                @media screen and (max-width: 500px) {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding-right: 0;
                    .ProfileImage{
                        margin-right: 0;
                    }
                    .ProfileContent{
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        &__Cats{
                            ul{
                                justify-content: center;
                            }
                        }
                    }
                }
            }
            &:last-child{
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            .dropdown-item{
                text-align: left;
                &:active{
                    background-color: $secondary-color;
                }
            }
        }
        &__Content{
            p{
                margin-bottom: 0;
            }
            &__attachments{
                padding: 0 10px;
                background-color: $section-color;
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                border-radius: $border-radius-smaller;
                h3{
                    margin: 10px 0;
                    font-weight: 500;
                    font-size: 0.95rem;
                    color: $primary-color;
                }
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        margin-right: 10px;
                        margin-bottom: 10px;
                        a{
                            position: relative;
                            span{
                                margin-right: 5px;
                                font-size: 0.75rem;
                            }
                            &::after{
                                content: '';
                                width: 0;
                                height: 1px;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                display: block;
                                background-color: $secondary-color;
                                transition: all 0.5s;
                            }
                            &:hover{
                                &::after{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 500px) {
                div{
                    text-align: center;
                }
            }
        }
    }
    &__Discussion{
        header{
            .ProfileImage{
                width: 50px;
                height: 50px;
                margin-right: 10px;
                figure{
                    width: 50px;
                    height: 50px;
                    img{
                        width: 50px;
                        min-height: 50px;
                    }
                }
            }
        }
    }
}
.inline_title{
    color: $primary-color;
    font-size: 0.95rem;
    font-weight: 500;
    text-transform: capitalize;
}
.ms-1{
    margin-left: 0.25rem;
}
.ms-2{
    margin-left: 0.5rem;
}
.ms-3{
    margin-left: 1rem;
}
.ms-4{
    margin-left: 1.5rem;
}
.ms-5{
    margin-left: 3rem;
}
[dir='rtl'] .ProjectDetails{
    &__ProjectData{
        margin-left: 0;
        margin-right: 0;
        border-left: 0 none;
        padding-left: 0;
        .Projects__List__Budget{
            margin-left: 25px;
            margin-right: 0;
            &:last-child{
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .ProfileContent{
        .ProfileTitle{
            margin-left: 10px;
            margin-right: 0;
        }
        .Title__Status{
            .ProfileName{
                margin-left: 15px;
                margin-right: 0;
            }
            .Status{
                padding-right: 25px;
                padding-left: 0;
                &::before{
                    right: 10px;
                    left: auto;
                }
            }
        }
        .Dashboard__Project__Owner{
            .ProfileImage{
                margin-left: 5px;
                margin-right: 0;
            }
            .Projects__List__Budget{
                margin-left: 25px;
                margin-right: 0;
            }
            .Owner__Name{
                margin-left: 15px;
                margin-right: 0;
            }
            .LocationTime{
                margin-left: 15px;
                margin-right: 0;
            }
        }
    }
    .HireMe{
        a{
            span{
                left: 0px;
                right: auto;
                padding-right: 0px;
                padding-left: 0px;
                i{
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
        }
    }
    &__Proposal{
        &__Card{
            header{
                .dropdown{
                    left: 0;
                    right: auto;
                    .dropdown-menu{
                        a{
                            span{
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
                .ProfileImage{
                    margin-left: 15px;
                    margin-right: 0;
                }
                .ProfileContent{
                    &__Details{
                        address{
                            margin-left: 10px;
                            margin-right: 0;
                        }
                        .ProfileName{
                            margin-left: 5px;
                            margin-right: 0;
                            .userRating{
                                margin-left: 0px;
                                margin-right: 10px;
                            }
                        }
                    }
                    &__Cost{
                        &__Price{
                            margin-left: 5px;
                            margin-right: 0;
                        }
                        time{
                            margin-right: 3px;
                            margin-left: 0;
                        }
                    }
                    &__Cats{
                        ul{
                            li{
                                margin-left: 10px;
                                margin-right: 0;
                                &:last-child{
                                    margin-left: 0;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    time{
                        span{
                            &.icon{
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
                @media screen and (max-width: 500px) {
                    padding-left: 0;
                    .ProfileImage{
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
            .dropdown-item{
                text-align: right;
            }
        }
        &__Content{
            &__attachments{
                ul{
                    li{
                        margin-left: 10px;
                        margin-right: 0;
                        a{
                            span{
                                margin-left: 5px;
                                margin-right: 0;
                            }
                            &::after{
                                right: 0;
                                left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    &__Discussion{
        header{
            .ProfileImage{
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }
}
[dir='rtl'] .ms-1{
    margin-right: 0.25rem;
    margin-left: 0;
}
[dir='rtl'] .ms-2{
    margin-right: 0.5rem;
    margin-left: 0;
}
[dir='rtl'] .ms-3{
    margin-right: 1rem;
    margin-left: 0;
}
[dir='rtl'] .ms-4{
    margin-right: 1.5rem;
    margin-left: 0;
}
[dir='rtl'] .ms-5{
    margin-right: 3rem;
    margin-left: 0;
}