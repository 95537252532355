.topfreelancer{
    padding: 15px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 390px;
    .freelancer-swiper{
        width: 100%;
        height: 100%;
        padding-left: 135px;
        position: relative;
        overflow: hidden;
        &::after{
            content: '';
            height: 3px;
            width: 70px;
            position: absolute;
            left: 80px;
            top: 38px;
            background-color: $purple-dark;
        }
        &::before{
            content: '';
            height: 3px;
            width: 130px;
            position: absolute;
            left: 20px;
            bottom: 42px;
            background-color: $pink;
        }
        .swiper-wrapper{
            width: calc(100% - 20px);
        }
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            &::before{
                display: none;
            }
            &::after{
                display: none;
            }
        }
    }
    &__Item{
        background-color: $white;
        border-radius: 25px;
        margin: 15px 0;
        height: 150px !important;

    }
    @media screen and (max-width: 768px) {
        height: 227px;
    }
    @media screen and (max-width: 500px) {
        height: 328px;
        &__Item{
            height: 250px !important;
        }
    }
}
[dir='rtl'] .topfreelancer{
    .freelancer-swiper{
        padding-right: 135px;
        padding-left: 0;
        &::after{
            right: 80px;
            left: auto;
        }
        &::before{
            right: 20px;
            left: auto;
        }
        @media screen and (max-width: 768px) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}