.ShowCase__List{
    position: relative;
    min-height: 400px;
    .freelancers__listItem{
        height: 300px;
        margin-bottom: 30px;
        figure{
            &:first-child{
                height: 230px;
                a{
                    width: 100%;
                    height: 100%;
                    position: relative;
                    display: flex;
                    &::before{
                        content: '';
                        width: 100%;
                        height: 50px;
                        position: absolute;
                        background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(81%, rgba(0, 0, 0, 0.6)));
                        background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 81%);
                        transition: all 0.3s;
                        bottom: -50px;
                    }
                    .portfolio__title{
                        width: 100%;
                        height: 50px;
                        display: flex;
                        margin-bottom: 0;
                        align-items: center;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        position: absolute;
                        bottom: -50px;
                        transition: all 0.3s;
                        padding: 0 0.9rem;
                        color: $white;
                        font-weight: 500;
                    }
                    &:hover{
                        &::before{
                            bottom: 0px;
                        }
                        .portfolio__title{
                            bottom: 0;
                        }
                    }
                }
                img{
                    max-height: inherit;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &:last-of-type{
                height: 70px;
                figcaption{
                    height: auto;
                    padding: 0.9rem;
                    align-items: center;
                }
                p{
                    margin-bottom: 0;
                }
            }
            .lazyload-wrapper{
                width: 100%;
            }
        }
        .More__Link{
            display: none;
        }
        .owner{
            &__profile{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                a{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap; 
                }
                .img-container{
                    position: relative;
                    bottom: auto;
                    margin-bottom: 0;
                    left: 0;
                    margin-left: 0;
                    margin-right: 5px;
                    width: 40px;
                    height: 40px;
                    img{
                        object-fit: cover;
                        min-height: 40px;
                        min-width: 40px;
                    }
                }
                .lazyload-wrapper{
                    width: 100%;
                }
            }
        }
    }
    .portfolio-swiper{
        .swiper-button-next {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: $white;
            background-color: $primary-dark;
            left: auto;
            right: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: calc(50% - 30px);
            &::after{
                font-size: 22px;
                color: $white;
            }
            &.swiper-button-disabled{
                opacity: 0.35;
            }
        }
        .swiper-button-prev {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: $white;
            background-color: $primary-dark;
            right: auto;
            left: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: calc(50% - 30px);
            &::after{
                font-size: 22px;
                color: $white;
            }
            &.swiper-button-disabled{
                opacity: 0.35;
            }
        }
    }
}
[dir='rtl'] .ShowCase__List{
    position: relative;
    min-height: 400px;
    .freelancers__listItem{
        direction: rtl;
        .owner{
            &__profile{
                .img-container{
                    right: 0;
                    left: auto;
                    margin-right: 0;
                    margin-left: 5px;
                }
            }
        }
    }
    .portfolio-swiper{
        .swiper-button-next {
            right: auto;
            left: 10px;
        }
        .swiper-button-prev {
            left: auto;
            right: 10px;
        }
    }
}