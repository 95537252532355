.Files{
    &__Accepted{
        h5{
            font-size: 0.95rem;
        }
        ul{
            margin-bottom: 1rem;
            li{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                flex-wrap: nowrap !important;
                .File{
                    &__Thumb{
                        display: flex;
                        min-width: 0;
                        overflow: hidden;
                        width: 100px;
                        height: 100px;
                        padding: 4px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        border: 1px solid #eaeaea;
                        margin-right: 8px;
                        .Image__Container{
                            display: inline-flex;
                            overflow: hidden;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            img{
                                display: block;
                                width: auto;
                                height: 100%;
                            }
                            i{
                                font-size: 48px;
                                color: $primary-color;
                            }
                        }
                    }
                    &__Content{
                        margin-left: 5px;
                        width: calc(100% - 105px);
                        h2{
                            color: $primary-color;
                            font-weight: 900;
                            font-size: 0.85rem;
                            margin-bottom: 0;
                        }
                        .progress{
                            margin-bottom: 4px;
                        }
                    }
                    &__Uploaded{
                        color: $green;
                        font-size: 0.75rem;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.Uploader{
    &__limit{
        font-weight: 500;
        color: $purple;
        &__item{
            margin-right: 5px;
            &::after{
                content: ',';
                display: inline-block;
            }
            &:last-child{
                margin-right: 0;
                &::after{
                    display: none;
                }
            }
        }
    }
}
[dir='rtl'] .Files{
    &__Accepted{
        ul{
            margin-bottom: 1rem;
            li{
                .File{
                    &__Thumb{
                        margin-left: 8px;
                        margin-right: 0;
                    }
                    &__Content{
                        margin-right: 5px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
[dir='rtl'] .Uploader{
    &__limit{
        &__item{
            margin-left: 5px;
            margin-right: 0;
            &:last-child{
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}