.Freelancers__List{
    .Profile-Card{
        height: 100%;
        margin-bottom: 0;
        .ProfileData{
            flex-direction: column;
            .ProfileContent{
                width: 100%;
                margin-top: 10px;
                .ProfileName{
                    text-align: center;
                    width: 100%;
                    justify-content: center;
                }
                .ProfileTitle{
                    text-align: center;
                }
                .LocationTime{
                    text-align: center;
                }
                .LastSeen{
                    text-align: center;
                }
            }
            .ProfileImage{
                margin-right: 0;
            }
            .btn-primary{
                margin-top: 10px;
                border-radius: $border-radius;
                background-color: $primary-color;
                border-color: $primary-color;
                transition: all 0.3s;
                padding: 3px 25px;
                &:hover{
                    background-color: $secondary-color;
                    border-color: $secondary-color;
                }
            }
        }
    }
}
[dir='rtl'] .Freelancers__List{
    .Profile-Card{
        .ProfileData{
            .ProfileImage{
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}